import { UserTokenDto } from './../models/SessionInfoDto';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { SessionActions } from '../state-management/session/session.actions';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { SessionInfoDto } from '../models/SessionInfoDto';
import { UserUiDto } from '../models/UserUiDto';
import { ApplicationConstants } from '../utils/ApplicationConstants';
import { UserService } from './user.service';
import { AdminService } from './admin.service';
import { ApplicationUtils } from '../utils/ApplicationUtils';
import { OnboardingService } from './onboarding.service';
import { MasterDataService } from '../master-data.service';
import { SocketService } from './socket.service';
import { selectCurrentNavigation } from '../state-management/navigation/navigation.features';
import { NavigationActions } from '../state-management/navigation/navigation.actions';
import { NavigationDto } from '../models/NavigationDto';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private messageService: MessageService,
    private userService: UserService,
    private adminService: AdminService,
    private socketService: SocketService,
    private onboardingService: OnboardingService,
    private masterDataService: MasterDataService,
    private store: Store
  ) { }

  async logout() {
    this.clearSession();
    await firstValueFrom(this.userService.logout());
    localStorage.clear();
  }

  async clearSession() {
    this.store.dispatch(SessionActions.clearSession())
    this.router.navigate(['/'], { skipLocationChange: true });
    this.socketService.disconnect();
  }

  public async initializeListenerAndDoRouting(sessionInfo: SessionInfoDto) {
    let userUiDto: UserUiDto = sessionInfo.userUiDto!;

    // TEMPORARY: This is a temporary fix to verify dsc. This will be removed once the dsc verification is done in the backend.
    const dscVerified = localStorage.getItem('dscVerified') === 'true';
    const updatedSessionInfo = Object.assign({}, sessionInfo, { dscVerified: dscVerified || false });

    const deploymentConfig = await firstValueFrom(this.adminService.getDeploymentConfiguration$);
    console.log("deploymentConfig", deploymentConfig);

    if (deploymentConfig?.nodeServerUrl) {
      this.socketService.connect(deploymentConfig?.nodeServerUrl, userUiDto);
    }
    // await this.applicationStartupCacheService.loadApplicationStartupDto();

    if (!ApplicationUtils.isSuperAdmin(userUiDto)) {
      //   this.applicationListenerService.loadAndListenBidderRegistrationDataOfAuctionHouse();
      //   await this.applicationListenerService.loadApplicationDataAfterLogin();
      await this.onboardingService.loadOrganizationUiDto();
      await this.onboardingService.loadShortCompaniesAndPlants();
      await this.masterDataService.initMasterData();
    }

    const currentNavigation = localStorage.getItem('currentNavigation');
    let navigation = currentNavigation != null ? JSON.parse(currentNavigation) as NavigationDto : null;
    if (navigation?.path == '/') {
      localStorage.removeItem('currentNavigation');
      navigation = null;
    }

    if (userUiDto.forcePasswordChange) {
      this.router.navigate(["/changePassword"], { skipLocationChange: true });
    } else {
      if (userUiDto.registrationCompleted) {
        if (ApplicationUtils.isSuperAdmin(userUiDto)) {
          if (navigation) {
            this.router.navigate([navigation.path], { skipLocationChange: true, state: navigation.data });
          } else {
            this.router.navigate(["/SuperAdmin"], { skipLocationChange: true });
          }
        } else {
          if (updatedSessionInfo.dscVerified) {
            if (navigation) {
              // Company details
              if (navigation.path.includes('/Admin/Organizations/Companies/') && navigation.data['companyUiDto']) {
                this.onboardingService.setCurrentCompanyUiDto(navigation.data['companyUiDto']);
              }

              this.router.navigate([navigation.path], { skipLocationChange: true, state: navigation.data });
            } else {
              this.router.navigate(["/Admin/"], { skipLocationChange: true });
            }
          } else {
            this.router.navigate(["/verifyDsc"], { skipLocationChange: true });
          }
        }
      } else {
        this.router.navigate(["/userOnboarding"], { skipLocationChange: true });
      }
    }
  }

  async handleServerResponse(data: any) {
    const userTokenDto = data.jwtData as UserTokenDto;
    localStorage.setItem('userTokenDto', JSON.stringify(userTokenDto));

    const sessionInfo = data.sessionInfoDto as SessionInfoDto;
    this.store.dispatch(SessionActions.saveSessionInfo({ sessionInfo }));

    await this.initializeListenerAndDoRouting(sessionInfo);
  }

  authenticate(username?: string, password?: string, otpId?: string): Observable<any> {
    if (!otpId) {
      otpId = "";
    }

    let headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(username + ':' + password + ':' + otpId) });
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.get<any>('auth/authenticate', { headers, observe: 'response', withCredentials: true }).pipe(
      map(apiResponseDto => {
        return apiResponseDto.body;
      }),
      catchError((error) => {
        return of(error.error);
      })
    )
  }

  async doAuthentication(userName: string, password: string, otpId?: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.authenticate(userName, password, otpId));

      // Clear Navigation Data
      this.store.dispatch(NavigationActions.clearCurrentPath());

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        await this.handleServerResponse(apiResponseDto.data);
      } else {
        localStorage.clear();
        this.store.dispatch(SessionActions.clearSession());

        if (apiResponseDto.code == "USER-107") {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: "Email Id Not Registered" });
        } else if (apiResponseDto.code == "USER-115") {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: "Account Disabled" });
        }
        else if (apiResponseDto.code == "USER-144") {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: "You are not authorized to login" });
        }
        else if (apiResponseDto.code == "COMMON-133") {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: "Kindly complete the registration by verifying the email link to proceed." });
        }
        else if (apiResponseDto.code == "COMMON-105") {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: "Invalid OTP" });
        }
        else if (apiResponseDto.code == "COMMON-106") {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: "OTP doesn't matched" });
        }
        else if (apiResponseDto.code == "COMMON-107") {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: "OTP expired" });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message });
        }
      }
    } catch (error) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Wrong Credentials" });
      localStorage.clear();
      this.store.dispatch(SessionActions.clearSession());
    }
  }

  authenticateWithOtp(username: string, userOtp: string, otpId: string): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(username + ':' + userOtp + ':' + otpId) });
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('WORKFLOW', 'LOGIN_WITH_OTP');

    return this.httpClient.post<any>('authenticate', null, { headers, observe: 'response' }).pipe(
      map(apiResponseDto => {
        if (apiResponseDto.body.code == ApplicationConstants.SUCCESS_CODE) {
          // session info only when authentication is successful.
          const userTokenDto = apiResponseDto.body.data.jwtData as UserTokenDto;
          localStorage.setItem('userTokenDto', JSON.stringify(userTokenDto));

          const sessionInfo = apiResponseDto.body.data.sessionInfoDto as SessionInfoDto;
          this.store.dispatch(SessionActions.saveSessionInfo({ sessionInfo }));
        }
        return apiResponseDto.body;
      }
      )
    )
  }

  async navigateBreadcrumbPage(index: number) {
    let navigation = await firstValueFrom(this.store.pipe(select(selectCurrentNavigation)));
    const pathParts = navigation?.path.split('/').filter(item => item !== '') || [];

    let path = pathParts.slice(0, index + 1).join('/');
    this.router.navigate([path], { skipLocationChange: true, state: navigation?.data || {} });
  }
}
