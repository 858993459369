import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SessionActions } from '../state-management/session/session.actions';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private router: Router,
    private store: Store,
    private messageService: MessageService
  ) { }

  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);

      if (error.status === 401 || this.isRedirectedToRoot(error)) {
        this.store.dispatch(SessionActions.clearSession());
        this.router.navigate(['/'], { skipLocationChange: true });
        localStorage.clear();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Session Timeout!" });
      } else if (error.name === "TimeoutError") {
        alert("Request Timeout, Try Again!");
      } else if (error.status !== 504) {
        // Optional: Log other errors to a server
        // this.errorHandlerService.sendErrorToServer(`${operation}`, error);
      }

      return of(result as T);
    };
  }
  

  isRedirectedToRoot(error: any) {
    if (error.error && error.error.text) {
      if (error.error.text.includes("<app-root></app-root>")) {
        return true;
      }
    }
    return false;
  }
}
