import { createFeature, createReducer, on } from "@ngrx/store";
import { PQActions } from "./pq.actions";
import { TenderSourcingEventWrapperUiDto } from "../../models/tenders/TenderSourcingEventWrapperUiDto";
import { SourcingEventUiDto } from "../../models/tenders/SourcingEventUiDto";
import { PqUiDto } from "../../models/pcpq/PqUiDto";
import { PqEvaluationWrapperDto } from "../../models/pcpq/PqEvaluationWrapperDto";

interface State {
  pqSourcingEventWrapperDto: TenderSourcingEventWrapperUiDto | null;
  sourcingEventUiDto: SourcingEventUiDto | null;
  pqUiDto: PqUiDto | null;
}

const initialState: State = {
  pqSourcingEventWrapperDto: null,
  sourcingEventUiDto: null,
  pqUiDto: null,
};

export const pqFeature = createFeature({
  name: 'PQFeature',
  reducer: createReducer(
    initialState,
    //SourcingEvent-----------------
    on(PQActions.setCurrentSourcingEventUiDto, (state, { sourcingEventUiDto }) => {
      localStorage.setItem('currentEvent', JSON.stringify(sourcingEventUiDto));
      return {
        ...state,
        sourcingEventUiDto
      }
    }),
    on(PQActions.getSourcingEventUiDto, (state) => ({
      ...state,
    })),
    //-----------------------

    // Current Pq Wrapper Ui Dto
    on(PQActions.setCurrentPqUiDto, (state, { pqUiDto }) => ({
      ...state,
      pqUiDto
    })),

    on(PQActions.getCurrentPqUiDto, (state) => ({
      ...state,
    })),
    on(PQActions.clearCurrentPqUiDto, (state) => ({
      ...state,
      pqUiDto: null,
    })),


    on(PQActions.getCurrentPqOpportunityWrapperDto, (state) => ({
      ...state
    })),
    on(PQActions.clearCurrentPqOpportunityWrapperDto, (state) => ({
      ...state,
      pqOpportunityWrapperDto: null
    })),


    on(PQActions.getAllAdminUsers, (state) => ({
      ...state
    })),

    on(PQActions.setPqDraftStatus, (state, { draftSubStatus: draftSubStatusStatus }) => ({
      ...state,
      sourcingEventUiDto: {
        ...state.sourcingEventUiDto,
        stages: {
          ...state.sourcingEventUiDto?.stages, // Preserve existing stages
          [draftSubStatusStatus]: true // Dynamically set the status to true
        }
      }
    })),

    
  ),
});

export const {
  name,
  reducer,
  //   selectPqManagementFeatureState,
  selectPqSourcingEventWrapperDto,
  selectPqUiDto,
  selectSourcingEventUiDto,
} = pqFeature