import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, Subscription } from 'rxjs';
import { UserService } from './shared/services/user.service';
import { selectSessionInfo, selectUserUiDto } from './shared/state-management/session/session.features';
import { AuthenticationService } from './shared/services/authentication.service';
import { AdminService } from './shared/services/admin.service';
import { SessionInfoDto } from './shared/models/SessionInfoDto';
import { UserUiDto } from './shared/models/UserUiDto';
import { NavigationActions } from './shared/state-management/navigation/navigation.actions';
import { OnboardingService } from './shared/services/onboarding.service';
import { SourcingEventUiDto } from './shared/models/tenders/SourcingEventUiDto';
import { TenderManagementActions } from './shared/state-management/tender/tender.actions';
import { AuctionActions } from './shared/state-management/auction/auction.actions';
import { selectSourcingEventUiDto } from './shared/state-management/tender/tender.features';
import { selectSourcingEventUiDto as selectSourcingEventUiDtoAuction } from './shared/state-management/auction/auction.features';
import { selectSourcingEventUiDto as selectSourcingEventUiDtoPQ } from './shared/state-management/pre_qualification/pq.features';
import { PQActions } from './shared/state-management/pre_qualification/pq.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnDestroy, AfterViewInit {
  title = 'procurext-ai-admin-ui';

  isLoggedIn: boolean = false;
  isShowHeader: boolean = true;

  hideBreadcrumbPaths: string[] = ['intermediate', 'verifyDsc'];

  routerSubscription?: Subscription;
  userUiDtoSubscription$?: Subscription;

  // @HostListener('window:beforeunload', ['$event'])
  // confirmBeforeUnload(event: Event): void {
  //   // Show confirmation dialog
  //   event.preventDefault();
  //   (event as BeforeUnloadEvent).returnValue = 'Are you sure you want to leave this page?';
  // }

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private adminService: AdminService,
    private onboardingService: OnboardingService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        this.checkNavigation(event);

        // Scroll to top on navigation
        window.scrollTo(0, 0);

        this.checkHeaderVisibility();
      }
    });

    this.userUiDtoSubscription$ = this.store.pipe(select(selectUserUiDto)).subscribe(userUiDto => {
      if (userUiDto) {
        this.isLoggedIn = userUiDto != null;
      } else {
        this.isLoggedIn = false;
      }
    });

    // Check if clear session from any tabs
    window.addEventListener('storage', (event) => {
      // Check if localStorage.clear() was called (event.key is null)
      if (event.key === null) {
        console.log('Local storage cleared. Ending session...');

        // Clear the session (call your service)
        this.authenticationService.clearSession();
        localStorage.clear();
      }

      // Additional logic if needed for specific keys
      if (event.key === 'userTokenDto') {
        const localUserTokenDto = localStorage.getItem('userTokenDto');

        if (!localUserTokenDto) {
          console.log('Session expired or manually cleared.');
          this.authenticationService.clearSession();
          localStorage.clear();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.disableAngularLoader();
    this.adminService.loadDeploymentConfiguration();
    this.onboardingService.loadLandingOrganizationUiDto();

    let localUserTokenDto = localStorage.getItem("userTokenDto");
    let isOnboardingUrl = location.pathname.includes('/onboarding');

    if (localUserTokenDto && !isOnboardingUrl) {
      // redirect to new intermediate page
      this.router.navigate(["/intermediate"], { skipLocationChange: true })

      this.handleRedirectionWithJwtToken();
    }
  }

  private disableAngularLoader() {
    let element = document.getElementById('idForDataLoader');
    if (element) {
      element.style.display = 'none';
      console.log("Loader disable successfully.")
    }
  }

  private async handleRedirectionWithJwtToken() {
    await this.userService.loadUserDetails();
    const sessionInfoDto = await firstValueFrom(this.store.pipe(select(selectSessionInfo))) as SessionInfoDto;

    let userUiDto: UserUiDto | undefined = sessionInfoDto?.userUiDto;

    if (userUiDto && userUiDto.userId) {
      this.authenticationService.initializeListenerAndDoRouting(sessionInfoDto);
    } else {
      this.router.navigate(['/'], { skipLocationChange: true });
    }

    // if (sessionInfoDto) {
    //   await this.dataRedirectionService.initializeUserListeners();
    // }
    // this.appLoader?.closeLoaderIcon(ApplicationConstants.LOADER_NOTIFICATION);
    // this.dataRedirectionService.navigateToPage();

  }

  checkHeaderVisibility() {
    // Get the current active route path
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    // Now, route should be the last active route
    let currentPath = route.snapshot.routeConfig?.path || '';

    // Set the visibility of the header based on the path
    if (currentPath === 'verifyDsc') {
      this.isShowHeader = false;
    } else {
      this.isShowHeader = true;
    }
  }

  async checkNavigation(event: NavigationEnd) {
    let data = this.router.getCurrentNavigation()?.extras.state || {};

    const isTenderUrl = event.url.includes('/Tender');
    const isAuctionUrl = event.url.includes('/Auction');
    const isPqUrl = event.url.includes('/Pre-Qualification');

    let currentEvent: SourcingEventUiDto | null = localStorage.getItem('currentEvent') ? JSON.parse(localStorage.getItem('currentEvent') as string) : null;
    let sourcingEventUiDto: SourcingEventUiDto | null = null;

    if (currentEvent && event.url.includes(currentEvent.eventId!)) {
      const selectSourcingEvent = isTenderUrl ? selectSourcingEventUiDto : isAuctionUrl ? selectSourcingEventUiDtoAuction : selectSourcingEventUiDtoPQ;
      // Retrieve the current sourcing event from the store
      const _sourcingEventUiDto = await firstValueFrom(this.store.pipe(select(selectSourcingEvent)));
      // Set the sourcing event to the current event if it is not already set
      sourcingEventUiDto = _sourcingEventUiDto ? _sourcingEventUiDto : currentEvent;

      // Set the current sourcing event to the store
      if (sourcingEventUiDto.eventType == 'TENDER') {
        this.store.dispatch(TenderManagementActions.setCurrentSourcingEventUiDto({ sourcingEventUiDto }));
      } else if (sourcingEventUiDto.eventType == 'AUCTION') {
        this.store.dispatch(AuctionActions.setCurrentSourcingEventUiDto({ sourcingEventUiDto }));
      } else if (sourcingEventUiDto.eventType == 'PQ') {
        this.store.dispatch(PQActions.setCurrentSourcingEventUiDto({ sourcingEventUiDto }));
      }
    }

    // Dispatch the updated path and state to the store
    if (!this.hideBreadcrumbPaths.includes(event.url.split('/')[1]) && event.url !== '/') {
      this.store.dispatch(NavigationActions.setCurrentPath({ navigation: { path: event.url, data: data } }));
    }
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.userUiDtoSubscription$) {
      this.userUiDtoSubscription$.unsubscribe();
    }
  }
}
