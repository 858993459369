import { createActionGroup, props, emptyProps } from "@ngrx/store";
import { TechnicalDetailsWrapperUiDto } from "../../models/questionnaire/TechnicalDetailsWrapperUiDto";
import { TenderEvaluationWrapperDto } from "../../models/questionnaire/TenderEvaluationWrapperDto";
import { BidderEvaluationDataDto } from "../../models/questionnaire/BidderEvaluationDataDto";
import { BidderTechnicalQuestionUiDto } from "../../models/questionnaire/BidderTechnicalQuestionUiDto";
import { EvaluationTechnicalQuestionUiDto } from "../../models/questionnaire/EvaluationTechnicalQuestionUiDto";
import { PqEvaluationWrapperDto } from "../../models/pcpq/PqEvaluationWrapperDto";
import { PqBidderEvaluationDataDto } from "../../models/pcpq/PqBidderEvaluationDataDto";
import { TechnicalQuestionUiDto } from "../../models/questionnaire/TechnicalQuestionUiDto";
import { BoqWrapperDto } from "../../models/questionnaire/BoqWrapperDto";

export const QuestionnaireActions = createActionGroup({
  source: 'Questionnaire',
  events: {
    'Set Questionnaire Details Ui Dto': props<{ technicalDetailsWrapperUiDto: TechnicalDetailsWrapperUiDto }>(),
    'Get Questionnaire Details Ui Dto': emptyProps(),
    'Clear Questionnaire Details Ui Dto': emptyProps(),

    // Temp Technical Questions
    'Set Temp Tech Questions': props<{ tempTechnicalQuestions: TechnicalQuestionUiDto[] }>(),
    'Update Temp Tech Questions': props<{ technicalQuestionUiDto: TechnicalQuestionUiDto }>(),
    'Get Temp Tech Questions': emptyProps(),
    'Delete Temp Tech Question': props<{ questionId: string }>(),
    'Clear Temp Tech Questions': emptyProps(),

    // Financial Boq Questions
    'Set Boq Wrapper Dto': props<{ boqWrapperDto: BoqWrapperDto }>(),
    'Get Boq Wrapper Dto': emptyProps(),
    'Clear Boq Wrapper Dto': emptyProps(),

    'Set Tender Evaluation Wrapper Dto': props<{ tenderEvaluationWrapperDto: TenderEvaluationWrapperDto }>(),
    'Get Tender Evaluation Wrapper Dto': emptyProps(),
    'Clear Tender Evaluation Wrapper Dto': emptyProps(),

    'Set Bidder Evaluation Data': props<{ bidderEvaluationDataDto: BidderEvaluationDataDto }>(),
    'Get Bidder Evaluation Data': emptyProps(),
    'Clear Bidder Evaluation Data': emptyProps(),

    'Update Bidder Technical Question Ui Dto': props<{ bidderTechnicalQuestionDto: BidderTechnicalQuestionUiDto }>(),

    'Update Re Evaluate Tech Question': props<{ reEvaluationTechnicalQuestion: EvaluationTechnicalQuestionUiDto }>(),
    'Clear Re Evaluate Tech Question': emptyProps(),
    
    // PQ
    'Set Pq Evaluation Wrapper Dto': props<{ pqEvaluationWrapperDto: PqEvaluationWrapperDto }>(),
    'Get Pq Evaluation Wrapper Dto': emptyProps(),
    'Clear Pq Evaluation Wrapper Dto': emptyProps(),

    'Set Pq Bidder Evaluation Data': props<{ pqBidderEvaluationDataDto: PqBidderEvaluationDataDto }>(),
    'Get Pq Bidder Evaluation Data': emptyProps(),
    'Clear Pq Bidder Evaluation Data': emptyProps(),

    'Update Pq Re Evaluate Tech Question': props<{ pqReEvaluationTechnicalQuestion: EvaluationTechnicalQuestionUiDto }>(),
    'Clear Pq Re Evaluate Tech Question': emptyProps(),

    'Update Pq Bidder Technical Question Ui Dto': props<{ pqBidderTechnicalQuestionDto: BidderTechnicalQuestionUiDto }>(),
  }
})