import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, tap, catchError, firstValueFrom, BehaviorSubject } from 'rxjs';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { ErrorService } from './error.service';
import { VerifyOtpWorkflowEnum } from '../enums/VerifyOtpWorkflowEnum';
import { AuthenticationResponseDto } from '../models/AuthenticationResponseDto';
import { SessionInfoDto } from '../models/SessionInfoDto';
import { ApplicationConstants } from '../utils/ApplicationConstants';
import { SessionActions } from '../state-management/session/session.actions';
import { Store, select } from '@ngrx/store';
import { selectUserUiDto } from '../state-management/session/session.features';
import { UserUiDto } from '../models/UserUiDto';
import { DscTransactionDto } from '../models/dsc/DscTransactionDto';
import { selectSourcingEventUiDto } from '../state-management/tender/tender.features';
import { selectSourcingEventUiDto as selectAuctionSourcingEventUiDto } from '../state-management/auction/auction.features';
import { UserInfoUiDto } from '../models/UserInfoUiDto';
import { selectOrganizationUiDto } from '../state-management/onboarding/onboarding.features';
import { ApplicationUtils } from '../utils/ApplicationUtils';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {

  otpWorkflow: VerifyOtpWorkflowEnum = VerifyOtpWorkflowEnum.SIMPLE_WORKFLOW;
  WorkFlowType?: string;
  emailForOnboarding?: string;
  emailVerificationId?: string;

  userInfoList$ = new BehaviorSubject<UserInfoUiDto[]>([]);
  userInfoList: UserInfoUiDto[] = [];

  constructor(
    private store: Store,
    private http: HttpClient,
    private errorService: ErrorService,
  ) { }

  get getOtpWorkflow() { return this.otpWorkflow; };

  get getEmailVerificationId() { return this.emailVerificationId; };
  get getEmailForOnboarding() { return this.emailForOnboarding; };
  get getWorkFlowType() { return this.WorkFlowType; }

  setOtpWorkflow(newWorkflow: VerifyOtpWorkflowEnum) {
    this.otpWorkflow = newWorkflow;
  }

  setEmailForOnboarding(emailForOnboarding: string) {
    this.emailForOnboarding = emailForOnboarding;
  }

  setEmailVerificationId(emailVerificationId: string) {
    this.emailVerificationId = emailVerificationId;
  }

  setWorkFlowType(WorkFlowType: string) {
    this.WorkFlowType = WorkFlowType;
  }

  async loadUserDetails() {
    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getUserDetails());
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let data = apiResponseDto.data as AuthenticationResponseDto;
        let sessionInfo = data.sessionInfoDto as SessionInfoDto;
        this.store.dispatch(SessionActions.saveSessionInfo({ sessionInfo }))
      }
    } catch (err) {
      console.error(err);
    }
  }

  async completeUserRegistration() {
    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.markRegistrationCompleted());
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let userUiDto = await firstValueFrom(this.store.pipe(select(selectUserUiDto)));
        if (userUiDto) {
          let modifiedUserUiDto = ApplicationUtils.deepClone(userUiDto) as UserUiDto;
          modifiedUserUiDto.registrationCompleted = true;
          this.store.dispatch(SessionActions.updateUserUiDto({ userUiDto: modifiedUserUiDto }));
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  updateUserDetails(userUiDto: UserUiDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('users', userUiDto).pipe(
      tap(_ => console.log("Update Details Successfully for User : " + userUiDto.userId)),
      catchError(this.errorService.handleError("Error while updating details for user : " + userUiDto.userId)))
  }

  logout(): Observable<ServerAPIResponseDto> {
    return this.http.post<any>('auth/logout', {}).pipe(
      tap(_ => console.log("logout successfully")),
      catchError(this.errorService.handleError<any>('Error while doing logout')))
  }

  private getUserDetails() {
    return this.http.get<string>('userDetails').pipe(
      catchError(this.errorService.handleError<any>('User session is not valid')))
  }

  generateOTP(emailId: string, name: string, workflowName: string): Observable<any> {
    let params = new HttpParams().set('emailId', emailId!).set('name', name).set('workFlowName', workflowName);
    return this.http.get<ServerAPIResponseDto>('OTPS', { params }).pipe(
      tap(_ => console.log("OTP Generate Successfully : " + emailId)),
      catchError(this.errorService.handleError("Error while generate : " + emailId)))
  }

  resendOTP(emailId: string, name: string, otpId: string): Observable<any> {
    let params = new HttpParams().set('emailId', emailId!).set('name', name!).set("otpId", otpId).set("resentWorkflow", "SIMPLE_WORKFLOW");
    return this.http.get<ServerAPIResponseDto>('resendOTPS', { params }).pipe(
      tap(_ => console.log("Resend OTP Generate Successfully : " + emailId)),
      catchError(this.errorService.handleError("Error while generate : " + emailId)))
  }

  sendResetPasswordLink(emailId: string, workflowName: string): Observable<any> {
    let params = new HttpParams().set('emailId', emailId).set('workflowName', workflowName);

    return this.http.get<ServerAPIResponseDto>('manage-user-password', { params }).pipe(
      tap(_ => console.log("Reset password successfully for User : " + emailId)),
      catchError(this.errorService.handleError("Error while forgot password for user : " + emailId)))
  }

  resendResetPasswordLink(emailVerificationId: string): Observable<any> {
    let params = new HttpParams().set('emailVerificationId', emailVerificationId);

    return this.http.get<ServerAPIResponseDto>('resend-email-verification', { params }).pipe(
      tap(_ => console.log("Resend password successfully for User : " + emailVerificationId)),
      catchError(this.errorService.handleError("Error while resending password for user : " + emailVerificationId)))
  }

  checkOnboardEmail(emailVerificationId: string): Observable<any> {
    let params = new HttpParams().set('emailVerificationId', emailVerificationId);

    return this.http.get<ServerAPIResponseDto>('check-onboard-email-invitation', { params }).pipe(
      tap(_ => console.log("EmailVerificationId checked : " + emailVerificationId)),
      catchError(this.errorService.handleError("Error while checking emailVerificationId : " + emailVerificationId)))
  }

  changePassword(emailVerificationId: string, password: string): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(encodeURIComponent(emailVerificationId!) + ':' + password) });

    return this.http.post<ServerAPIResponseDto>('onboard-reset-password', null, { headers }).pipe(
      tap(_ => console.log("Password Reset Successfully for User : " + emailVerificationId)),
      catchError(this.errorService.handleError("Error while resetting password for user : " + emailVerificationId)))
  }

  sendAddDscEvent(dscTransactionDto: DscTransactionDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('dsc/transaction', dscTransactionDto).pipe(
      tap(_ => console.log("Add DSC event send successfully : " + dscTransactionDto.userId)),
      catchError(this.errorService.handleError("Error while sending DSC event : " + dscTransactionDto.userId)))
  }

  sendDeleteDscEvent(dscTransactionDto: DscTransactionDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('dsc/transaction', dscTransactionDto).pipe(
      tap(_ => console.log("Delete DSC event send successfully : " + dscTransactionDto.userId)),
      catchError(this.errorService.handleError("Error while sending DSC event : " + dscTransactionDto.userId)));
  }

  sendVerifyDscEvent(serialNumber: string): Observable<any> {
    return this.http.get<ServerAPIResponseDto>(`dsc/login-attribute/${serialNumber}`).pipe(
      tap(_ => console.log("Verify DSC event send successfully : " + serialNumber)),
      catchError(this.errorService.handleError("Error while verifying DSC event : " + serialNumber)));
  }

  removeUser(orgCode: string, companyCode: string, plantCode: string, emailId: string,): Observable<any> {
    return this.http.post<any>(`internal-users/removeUserMapping/${orgCode}/${companyCode}/${plantCode}/${emailId}`, '').pipe(
      tap(_ => console.log("API called successfully with: ")),
      catchError(this.errorService.handleError<any>('Error while calling the remove user API'))
    );
  }

  private markRegistrationCompleted(): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('internal-users/mark-registration-completed', {}).pipe(
      tap(_ => console.log("User registration successfully : ")),
      catchError(this.errorService.handleError("Error while user registration : ")));
  }


  //Get Organigation's User List ---------------------------
  async getUsersBasedOnOrgCode(orgCode: string, companyCode: string, plantCode: string) {
    try {

      let apiResponseDto = await firstValueFrom(this.fetchUserList(
        orgCode!,
        companyCode!,
        plantCode!));
      //console.log('apiResponseDtoEvent', apiResponseDto)
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setAddressData(apiResponseDto.data as UserInfoUiDto[])
      }
    } catch (error) {
      console.error(error);
    }
  }
  setAddressData(userInfoList: UserInfoUiDto[]) {
    this.userInfoList = userInfoList;
    this.userInfoList$.next(this.userInfoList);
  }
  fetchUserList(orgId: string, companyCode: string, plantCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`internal-users/${orgId}/${companyCode}/${plantCode}`).pipe(
      tap(_ => console.log("Fetch tender list")),
      catchError(this.errorService.handleError<any>("Error while fetching tender configdata")));
  }

  //---------------------------------------------------
  ngOnDestroy(): void {

  }
}
