import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { OrgOrganogramUiDto } from "../../models/OrgOrganogramUiDto";
import { OnboardingActions } from "./onboarding.actions";
import { CompanyLightUiDto, PlantLightUiDto } from "../../models/CompanyLightWrapperUiDto";

interface State {
  orgOrganogramUiDto: OrgOrganogramUiDto | null;
  companyCode: string | null;
  plantCode: string | null;
  companyShortUiDtos: CompanyLightUiDto[],
  plantShortUiDtos: PlantLightUiDto[],
}

const initialState: State = {
  orgOrganogramUiDto: null,
  companyCode: null,
  plantCode: null,
  companyShortUiDtos: [],
  plantShortUiDtos: []
};

export const onboardingFeature = createFeature({
  name: 'onboarding',
  reducer: createReducer(
    initialState,
    on(OnboardingActions.saveOrgOrganogram, (state, { orgOrganogramUiDto }) => ({
      ...state,
      orgOrganogramUiDto,
    })),
    on(OnboardingActions.getOrgOrganogram, (state) => ({
      ...state,
    })),
    on(OnboardingActions.clearOrgOrganogram, (state) => ({
      ...state,
      orgOrganogramUiDto: null,
    })),

    // Update Organization
    on(OnboardingActions.updateOrganizationUiDto, (state, { organizationWrapperUiDto }) => ({
      ...state,
      orgOrganogramUiDto: {
        ...state.orgOrganogramUiDto,
        organizationWrapperUiDto: organizationWrapperUiDto
      }
    })),

    // Short Data
    on(OnboardingActions.setShortCompaniesData, (state, { companyShortUiDtos }) => ({
      ...state,
      companyShortUiDtos
    })),
    on(OnboardingActions.updateShortCompaniesData, (state, { companyShortUiDto }) => {
      const existingIndex = (state.companyShortUiDtos || []).findIndex(
        dto => dto.companyCode === companyShortUiDto.companyCode
      );
    
      const updatedList = [...(state.companyShortUiDtos || [])];
    
      if (existingIndex > -1) {
        // Update the existing company
        updatedList[existingIndex] = companyShortUiDto;
      } else {
        // Add the new company
        updatedList.push(companyShortUiDto);
      }
    
      return {
        ...state,
        companyShortUiDtos: updatedList
      };
    }),
    on(OnboardingActions.getShortCompaniesData, (state) => ({
      ...state,
    })),

    on(OnboardingActions.setShortPlantsData, (state, { plantShortUiDtos }) => ({
      ...state,
      plantShortUiDtos
    })),
    on(OnboardingActions.updateShortPlantsData, (state, { plantShortUiDto }) => {
      const existingIndex = (state.plantShortUiDtos || []).findIndex(
        dto =>
          dto.companyCode === plantShortUiDto.companyCode &&
          dto.plantCode === plantShortUiDto.plantCode
      );
    
      const updatedList = [...(state.plantShortUiDtos || [])];
    
      if (existingIndex > -1) {
        // Update the existing plant
        updatedList[existingIndex] = plantShortUiDto;
      } else {
        // Add the new plant
        updatedList.push(plantShortUiDto);
      }
    
      return {
        ...state,
        plantShortUiDtos: updatedList
      };
    }),    
    on(OnboardingActions.getShortPlantsData, (state) => ({
      ...state,
    })),
  ),
  extraSelectors: ({ selectOrgOrganogramUiDto, selectCompanyCode, selectPlantCode }) => {
    const selectOrganizationUiDto = createSelector(
      selectOrgOrganogramUiDto,
      (orgOrganogramUiDto: OrgOrganogramUiDto | null) =>
        orgOrganogramUiDto?.organizationWrapperUiDto || null
    );

    const selectCompanyByCode = createSelector(
      selectOrgOrganogramUiDto,
      selectCompanyCode,
      (orgOrganogramUiDto, companyCode) =>
        orgOrganogramUiDto?.companyUiDtos?.find(
          (company) => company.companyCode === companyCode
        ) || null
    );

    const selectPlantByCompanyAndPlantCode = createSelector(
      selectCompanyByCode,
      selectPlantCode,
      (company, plantCode) =>
        company?.plantUiDtos?.find((plant) => plant.plantCode === plantCode) ||
        null
    );

    return {
      selectOrganizationUiDto,
      selectCompanyByCode,
      selectPlantByCompanyAndPlantCode,
    };
  },
});

export const {
  name,
  reducer,
  selectOnboardingState,
  selectOrgOrganogramUiDto,
  selectCompanyByCode,
  selectPlantByCompanyAndPlantCode,
  selectOrganizationUiDto,
  selectCompanyShortUiDtos,
  selectPlantShortUiDtos
} = onboardingFeature