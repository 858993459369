import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { SourcingEventUiDto } from "../../models/tenders/SourcingEventUiDto";
import { DraftSubStatus } from "../../enums/DraftSubStatus";
import { PqUiDto } from "../../models/pcpq/PqUiDto";
import { BidderEvaluationDataDto } from "../../models/questionnaire/BidderEvaluationDataDto";
import { PqEvaluationWrapperDto } from "../../models/pcpq/PqEvaluationWrapperDto";
import { PqBidderEvaluationDataDto } from "../../models/pcpq/PqBidderEvaluationDataDto";

export const PQActions = createActionGroup({
  source: 'PQ',
  events: {
    'Set Current Sourcing Event Ui Dto': props<{ sourcingEventUiDto: SourcingEventUiDto }>(),
    'Get Sourcing Event Ui Dto': emptyProps(),

    'Set Current Pq Ui Dto': props<{ pqUiDto: PqUiDto }>(),
    'Get Current Pq Ui Dto': emptyProps(),
    'Clear Current Pq Ui Dto': emptyProps(),

    'Get Current Pq Opportunity Wrapper Dto': emptyProps(),
    'Clear Current Pq Opportunity Wrapper Dto': emptyProps(),

    'Get All Admin Users': emptyProps(),

    'Set Bidder Evaluation Data': props<{ bidderEvaluationDataDto: PqBidderEvaluationDataDto }>(),
    'Get Bidder Evaluation Data': emptyProps(),
    'Clear Bidder Evaluation Data': emptyProps(),

    'Set Pq Draft Status': props<{ draftSubStatus: DraftSubStatus }>(),
  }
})