import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(val?:string , length:number = 20):string {
    if (!val) return '';
    return val.length > length ? `${val.substring(0, length)} ...` : val
  }

}
