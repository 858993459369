import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { EncryptionService } from '../services/encryption.service';
import { UserTokenDto } from '../models/SessionInfoDto';

@Injectable()
export class EncryptionInterceptor implements HttpInterceptor {

  constructor(private encryptionService: EncryptionService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clonedRequest = req;
    let localUserTokenDto = localStorage.getItem('userTokenDto');
    const userTokenDto = localUserTokenDto ? JSON.parse(localUserTokenDto) as UserTokenDto : undefined;

    // Extract encryption key safely
    let key = userTokenDto?.jwtToken && userTokenDto.jwtToken.length >= 32
      ? userTokenDto.jwtToken.substring(0, 32)
      : null;

    if (req.headers.has('X-SECURE-PAYLOAD') && key) {
      if (clonedRequest.body) {
        try {
          const encryptedBody = this.encryptionService.encryptData(clonedRequest.body, key);
          const updatedHeaders = clonedRequest.headers.set('Content-Type', 'application/json');
          clonedRequest = clonedRequest.clone({
            headers: updatedHeaders,
            body: encryptedBody,
            responseType: 'text' // Ensure responseType is text for encrypted responses
          });
        } catch (error) {
          console.error("Encryption failed:", error);
        }
      }
    }

    return next.handle(clonedRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body) {
          if (event.headers.has('X-Secure-Payload') && key) {
            try {
              let responseBody = (event.body as string).replace(/\s+/g, ''); // Trim spaces safely
              const decryptedBody = this.encryptionService.decryptData(responseBody, key);

              const updatedHeaders = event.headers.set('Content-Type', 'application/json');
              const modifiedEvent = event.clone({
                body: JSON.parse(decryptedBody),
                headers: updatedHeaders,
              });

              return modifiedEvent;
            } catch (error) {
              console.error("Decryption failed or JSON parsing error:", error);
              return event; // Return original event if decryption fails
            }
          }
        }
        return event;
      })
    );
  }
}
