import { createFeature, createReducer, on } from "@ngrx/store";
import { TechnicalDetailsWrapperUiDto } from "../../models/questionnaire/TechnicalDetailsWrapperUiDto";
import { QuestionnaireActions } from "./questionnaire.actions";
import { TenderEvaluationWrapperDto } from "../../models/questionnaire/TenderEvaluationWrapperDto";
import { BidderEvaluationDataDto } from "../../models/questionnaire/BidderEvaluationDataDto";
import { EvaluationTechnicalQuestionUiDto } from "../../models/questionnaire/EvaluationTechnicalQuestionUiDto";
import { PqEvaluationWrapperDto } from "../../models/pcpq/PqEvaluationWrapperDto";
import { PqBidderEvaluationDataDto } from "../../models/pcpq/PqBidderEvaluationDataDto";
import { TechnicalQuestionUiDto } from "../../models/questionnaire/TechnicalQuestionUiDto";
import { BoqWrapperDto } from "../../models/questionnaire/BoqWrapperDto";

interface State {
  technicalDetailsWrapperUiDto: TechnicalDetailsWrapperUiDto | null
  boqWrapperDto: BoqWrapperDto | null
  tenderEvaluationWrapperDto: TenderEvaluationWrapperDto | null
  bidderEvaluationDataDto: BidderEvaluationDataDto | null
  reEvaluationTechnicalQuestion: EvaluationTechnicalQuestionUiDto | null
  pqEvaluationWrapperDto: PqEvaluationWrapperDto | null
  pqBidderEvaluationDataDto: PqBidderEvaluationDataDto | null
  pqReEvaluationTechnicalQuestion: EvaluationTechnicalQuestionUiDto | null

  // Temp Tech Questions
  tempTechnicalQuestions: TechnicalQuestionUiDto[]
}

const initialState: State = {
  technicalDetailsWrapperUiDto: null,
  boqWrapperDto: null,
  tenderEvaluationWrapperDto: null,
  bidderEvaluationDataDto: null,
  reEvaluationTechnicalQuestion: null,
  pqEvaluationWrapperDto: null,
  pqBidderEvaluationDataDto: null,
  pqReEvaluationTechnicalQuestion: null,
  tempTechnicalQuestions: []
};


export const questionnaireFeature = createFeature({
  name: 'QuestionnaireFeature',
  reducer: createReducer(
    initialState,

    on(QuestionnaireActions.setQuestionnaireDetailsUiDto, (state, { technicalDetailsWrapperUiDto }) => ({
      ...state,
      technicalDetailsWrapperUiDto
    })),    
    on(QuestionnaireActions.getQuestionnaireDetailsUiDto, (state) => ({
      ...state,
    })),
    on(QuestionnaireActions.clearQuestionnaireDetailsUiDto, (state) => ({
      ...state,
      technicalDetailsWrapperUiDto: null,
    })),

    // Financial Boq Questions
    on(QuestionnaireActions.setBoqWrapperDto, (state, { boqWrapperDto }) => {
      return {
        ...state,
        boqWrapperDto
      };
    }),
    on(QuestionnaireActions.getBoqWrapperDto, (state) => ({
      ...state,
    })),
    on(QuestionnaireActions.clearBoqWrapperDto, (state) => ({
      ...state,
      boqWrapperDto: null,
    })),

    on(QuestionnaireActions.setTenderEvaluationWrapperDto, (state, { tenderEvaluationWrapperDto }) => ({
      ...state,
      tenderEvaluationWrapperDto
    })),
    on(QuestionnaireActions.getTenderEvaluationWrapperDto, (state) => ({
      ...state,
    })),
    on(QuestionnaireActions.clearTenderEvaluationWrapperDto, (state) => ({
      ...state,
      tenderEvaluationWrapperDto: null,
    })),

    on(QuestionnaireActions.setBidderEvaluationData, (state, { bidderEvaluationDataDto }) => {
      // Find the index of the bidder summary data to update
      const updatedBidderEvaluationSummaryDataDtoList = state.tenderEvaluationWrapperDto?.bidderEvaluationSummaryDataDtoList?.map((summary) => {
        if (summary.bidderUserId === bidderEvaluationDataDto.bidderUserId) {
          // Update the evaluationStatus field
          return {
            ...summary,
            evaluationStatus: bidderEvaluationDataDto.evaluationStatus,
          };
        }
        return summary; // Keep other summaries unchanged
      });

      return {
        ...state,
        bidderEvaluationDataDto, // Update bidderEvaluationDataDto
        tenderEvaluationWrapperDto: {
          ...state.tenderEvaluationWrapperDto,
          bidderEvaluationSummaryDataDtoList: updatedBidderEvaluationSummaryDataDtoList || state.tenderEvaluationWrapperDto?.bidderEvaluationSummaryDataDtoList,
        },
      };
    }),
    on(QuestionnaireActions.getBidderEvaluationData, (state) => ({
      ...state,
    })),
    on(QuestionnaireActions.clearBidderEvaluationData, (state) => ({
      ...state,
      bidderEvaluationDataDto: null,
    })),

    on(QuestionnaireActions.setPqBidderEvaluationData, (state, { pqBidderEvaluationDataDto }) => {
      // Find the index of the bidder summary data to update
      const updatedPqBidderEvaluationSummaryDataDtoList = state.pqEvaluationWrapperDto?.bidderEvaluationSummaryDataDtoList?.map((summary) => {
        if (summary.bidderUserId === pqBidderEvaluationDataDto.bidderUserId) {
          // Update the evaluationStatus field
          return {
            ...summary,
            evaluationStatus: pqBidderEvaluationDataDto.evaluationStatus,
          };
        }
        return summary; // Keep other summaries unchanged
      });

      return {
        ...state,
        pqBidderEvaluationDataDto, // Update bidderEvaluationDataDto
        pqEvaluationWrapperDto: {
          ...state.pqEvaluationWrapperDto,
          bidderEvaluationSummaryDataDtoList: updatedPqBidderEvaluationSummaryDataDtoList || state.pqEvaluationWrapperDto?.bidderEvaluationSummaryDataDtoList,
        },
      };
    }),
    on(QuestionnaireActions.getPqBidderEvaluationData, (state) => ({
      ...state,
    })),
    on(QuestionnaireActions.clearPqBidderEvaluationData, (state) => ({
      ...state,
      pqBidderEvaluationDataDto: null,
    })),

    on(QuestionnaireActions.updateBidderTechnicalQuestionUiDto, (state, { bidderTechnicalQuestionDto }) => ({
      ...state,
      bidderEvaluationDataDto: {
        ...state.bidderEvaluationDataDto,
        evaluationTechnicalQuestionUiDtos: updateOrAddEvaluationTechnicalQuestion(
          state.bidderEvaluationDataDto?.evaluationTechnicalQuestionUiDtos,
          bidderTechnicalQuestionDto
        ),
      },
    })),

    on(QuestionnaireActions.updateReEvaluateTechQuestion, (state, { reEvaluationTechnicalQuestion }) => ({
      ...state,
      reEvaluationTechnicalQuestion
    })),
    on(QuestionnaireActions.clearReEvaluateTechQuestion, (state) => ({
      ...state,
      reEvaluationTechnicalQuestion: null
    })),

    on(QuestionnaireActions.setPqEvaluationWrapperDto, (state, { pqEvaluationWrapperDto }) => ({
      ...state,
      pqEvaluationWrapperDto
    })),
    on(QuestionnaireActions.getPqEvaluationWrapperDto, (state) => ({
      ...state,
    })),
    on(QuestionnaireActions.clearPqEvaluationWrapperDto, (state) => ({
      ...state,
      pqEvaluationWrapperDto: null,
    })),

    on(QuestionnaireActions.updatePqReEvaluateTechQuestion, (state, { pqReEvaluationTechnicalQuestion }) => ({
      ...state,
      pqReEvaluationTechnicalQuestion
    })),
    on(QuestionnaireActions.clearPqReEvaluateTechQuestion, (state) => ({
      ...state,
      pqReEvaluationTechnicalQuestion: null
    })),


    on(QuestionnaireActions.updatePqBidderTechnicalQuestionUiDto, (state, { pqBidderTechnicalQuestionDto }) => ({
      ...state,
      pqBidderEvaluationDataDto: {
        ...state.pqBidderEvaluationDataDto,
        evaluationTechnicalQuestionUiDtos: updateOrAddEvaluationTechnicalQuestion(
          state.pqBidderEvaluationDataDto?.evaluationTechnicalQuestionUiDtos,
          pqBidderTechnicalQuestionDto
        ),
      },
    })),

    // Temp Technical Questions
    on(QuestionnaireActions.setTempTechQuestions, (state, { tempTechnicalQuestions }) => ({
      ...state,
      tempTechnicalQuestions
    })),
    on(QuestionnaireActions.updateTempTechQuestions, (state, { technicalQuestionUiDto }) => {
      const existingIndex = state.tempTechnicalQuestions.findIndex(q => q.questionId === technicalQuestionUiDto.questionId);
    
      let updatedQuestions;
      if (existingIndex !== -1) {
        // Update existing question
        updatedQuestions = state.tempTechnicalQuestions.map((q, index) =>
          index === existingIndex ? { ...q, ...technicalQuestionUiDto } : q
        );
      } else {
        // Add new question
        updatedQuestions = [...state.tempTechnicalQuestions, technicalQuestionUiDto];
      }
    
      return {
        ...state,
        tempTechnicalQuestions: updatedQuestions
      };
    }),
    on(QuestionnaireActions.getTempTechQuestions, (state) => ({
      ...state,
    })),
    on(QuestionnaireActions.deleteTempTechQuestion, (state, { questionId }) => ({
      ...state,
      tempTechnicalQuestions: state.tempTechnicalQuestions.filter(q => q.questionId !== questionId),
    })),    
    on(QuestionnaireActions.clearTempTechQuestions, (state) => ({
      ...state,
      tempTechnicalQuestions: [],
    })),
  ),
});

const updateOrAddEvaluationTechnicalQuestion = (
  currentArray: EvaluationTechnicalQuestionUiDto[] | undefined,
  newDto: EvaluationTechnicalQuestionUiDto
): EvaluationTechnicalQuestionUiDto[] => {
  const array = currentArray || [];
  const index = array.findIndex(dto => dto.questionId === newDto.questionId);

  if (index > -1) {
    // Update existing DTO
    return [
      ...array.slice(0, index),
      { ...array[index], ...newDto }, // Merge changes
      ...array.slice(index + 1),
    ];
  } else {
    // Add new DTO
    return [...array, newDto];
  }
};

export const {
  name,
  reducer,
  selectTechnicalDetailsWrapperUiDto,
  selectBoqWrapperDto,
  selectTenderEvaluationWrapperDto,
  selectBidderEvaluationDataDto,
  selectReEvaluationTechnicalQuestion,
  selectPqEvaluationWrapperDto,
  selectPqBidderEvaluationDataDto,
  selectPqReEvaluationTechnicalQuestion,
  selectTempTechnicalQuestions
} = questionnaireFeature