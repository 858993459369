import { Injectable } from '@angular/core';

import * as forge from 'node-forge';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  // encryptData(dataToEncrypt: any, key: string) {
  //   let secretKey = CryptoJS.enc.Utf8.parse(key);
  //   let jsonString = JSON.stringify(dataToEncrypt);
  //   let encrypted = CryptoJS.AES.encrypt(jsonString, secretKey, {
  //     mode: CryptoJS.mode.ECB,
  //     padding: CryptoJS.pad.Pkcs7,
  //   });

  //   return encrypted.toString();
  // }

  // decryptData(cipherText: string, key: string) {
  //   let secretKey = CryptoJS.enc.Utf8.parse(key);
  //   const bytes = CryptoJS.AES.decrypt(cipherText, secretKey, {
  //     mode: CryptoJS.mode.ECB,
  //     padding: CryptoJS.pad.Pkcs7,
  //   });
  //   return bytes.toString(CryptoJS.enc.Utf8);
  // }

  encryptData(dataToEncrypt: any, key: string): string {
    // Ensure the key is 16, 24, or 32 bytes
    let secretKey = forge.util.createBuffer(key.padEnd(32, ' '), 'utf8').getBytes().slice(0, 32);

    // Convert data to JSON string
    let jsonString = JSON.stringify(dataToEncrypt);

    // Create AES cipher in ECB mode (NO IV)
    let cipher = forge.cipher.createCipher('AES-ECB', secretKey);
    cipher.start();  // ECB mode does not require IV
    cipher.update(forge.util.createBuffer(jsonString, 'utf8'));
    cipher.finish();

    // Return base64 encoded encrypted data
    return forge.util.encode64(cipher.output.getBytes());
  }


  decryptData(cipherText: string, key: string): string {
    // Ensure the key is 16, 24, or 32 bytes
    let secretKey = forge.util.createBuffer(key.padEnd(32, ' '), 'utf8').getBytes().slice(0, 32);

    // Decode the base64 encrypted data
    let encryptedBytes = forge.util.decode64(cipherText);

    // Create AES decipher in ECB mode
    let decipher = forge.cipher.createDecipher('AES-ECB', secretKey);

    // ECB does not use IV, so do not pass it
    decipher.start();
    decipher.update(forge.util.createBuffer(encryptedBytes, 'raw'));

    let result = decipher.finish();
    if (result) {
      return decipher.output.toString();
    } else {
      throw new Error('Decryption failed');
    }
  }

  encryptDataWithPublicKey(dataToEncrypt: string, publicKeyPem: any) {
    try {
      // Convert PEM to Public Key
      const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
  
      // Encrypt using PKCS#1 v1.5 (matches .NET's RSAEncryptionPadding.Pkcs1)
      const encrypted = publicKey.encrypt(forge.util.encodeUtf8(dataToEncrypt), 'RSAES-PKCS1-V1_5');
  
      // Return Base64 encoded ciphertext
      return forge.util.encode64(encrypted);
    } catch (error) {
      console.error('Encryption failed:', error);
      throw new Error('Encryption error');
    }
  }
}
