import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { io, Socket } from 'socket.io-client';
import { UserUiDto } from '../models/UserUiDto';
import { firstValueFrom } from 'rxjs';
import { DscNotificationEnum } from '../enums/DscNotificationEnum';
import { MessageService } from 'primeng/api';
import { UserService } from './user.service';
import { selectSessionInfo, selectUserUiDto } from '../state-management/session/session.features';
import { OrganizationWrapperUiDto } from '../models/OrganizationWrapperUiDto';
import { OnboardingActions } from '../state-management/onboarding/onboarding.actions';
import { TenderUiDto } from '../models/tenders/TenderUiDto';
import { selectTenderUiDto } from '../state-management/tender/tender.features';
import { TenderManagementActions } from '../state-management/tender/tender.actions';
import { CacheUpdateTypes } from '../utils/CacheUpdateTypes';
import { DscNotificationDto } from '../models/DscNotificationDto';
import { Router } from '@angular/router';
import { SessionInfoDto } from '../models/SessionInfoDto';
import { ApplicationUtils } from '../utils/ApplicationUtils';
import { SessionActions } from '../state-management/session/session.actions';
import { ApplicationConstants } from '../utils/ApplicationConstants';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService implements OnDestroy {
  private socket?: Socket;
  private serverUrl = '';
  private reconnectInterval = 2000; // 2 seconds
  private heartbeatInterval?: any;

  userUiDto?: UserUiDto;
  private dscSignStatus$ = new BehaviorSubject<boolean>(false);


  constructor(
    private store: Store,
    private router: Router,
    private userService: UserService,
    private messageService: MessageService
  ) {
    this.handleVisibilityChange();
  }

  get getDscSign$() { return this.dscSignStatus$.asObservable(); };



  async loadUserUiDto() {
    this.userUiDto = await firstValueFrom(this.store.select(selectUserUiDto));
  }

  reconnectSocket() {
    this.socket = io(`wss://${this.serverUrl}`, {
      transports: ['websocket'], // Ensure WebSocket is the primary transport
    });
  }

  connect(serverUrl: string) {
    this.serverUrl = serverUrl;

    // Load user ui dto
    this.loadUserUiDto();

    this.socket = io(`wss://${serverUrl}`, {
      transports: ['websocket'], // Ensure WebSocket is the primary transport
    });

    this.socket.on('connect', () => {
      console.log('Connected to Socket.IO server');
      this.socket?.emit('register', { id: this.socket?.id, user: this.userUiDto?.primaryEmailId });

      // Start the heartbeat if it hasn't been started yet
      if (!this.heartbeatInterval) {
        this.startHeartbeat();
      }
    });

    this.socket.on('disconnect', (reason) => {
      console.log('Disconnected:', reason);
      this.socket?.emit('unregister', { id: this.socket?.id, user: this.userUiDto?.primaryEmailId });

      setTimeout(() => {
        if (!this.socket) this.connect(serverUrl);
      }, this.reconnectInterval); // Reconnect on error
    });

    this.socket.on('connect_error', (error) => {
      console.error('Connection Error:', error);
      setTimeout(() => {
        if (!this.socket) this.connect(serverUrl);
      }, this.reconnectInterval); // Reconnect on error
    });

    this.socket.on('reconnect_attempt', () => {
      console.log('Reconnecting...');
    });

    this.socket.on('redisDashboardCacheUpdates', (message) => {
      console.log(message);
      this.handleRedisCacheUpdates(JSON.parse(message));
    })

    this.socket.on('DSC_CHANNEL', (message) => {
      console.log(message);
      this.handleDscUpdates(JSON.parse(message));
    })

    this.socket.on('pong', (message) => {
      console.log(message);
    })
  }

  private startHeartbeat() {
    this.heartbeatInterval = setInterval(() => {
      if (this.socket && this.socket.connected) {
        this.socket.emit('ping', { id: this.socket?.id, user: this.userUiDto?.primaryEmailId, timestamp: new Date().toLocaleString() }); // Send a ping message
      }
    }, 30000); // Send a ping every 30 seconds
  }

  private clearHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
  }

  private handleVisibilityChange() {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden && !this.socket?.connected) {
        console.log('Tab is active again, reconnecting socket...');
        if (this.socket) {
          this.socket?.connect();
        } else {
          this.reconnectSocket();
        }
      }
    });
  }

  private async handleRedisCacheUpdates(data: any) {
    if (data['cacheUpdateType'] == CacheUpdateTypes.ORG_WRAPPER) {
      const organizationWrapperUiDto = data as OrganizationWrapperUiDto;
      this.store.dispatch(OnboardingActions.updateOrganizationUiDto({ organizationWrapperUiDto }))
    }

    if (data['cacheUpdateType'] == CacheUpdateTypes.TENDER_WRAPPER) {
      const tenderUiDto = data as TenderUiDto;
      const oldTenderWrapperUiDto = await firstValueFrom(this.store.pipe(select(selectTenderUiDto)));
      if (tenderUiDto.tenderId == oldTenderWrapperUiDto?.tenderId) {
        this.store.dispatch(TenderManagementActions.setCurrentTenderUiDto({ tenderUiDto }))
      }

    }
  }

  private async handleDscUpdates(dscNotificationDto: DscNotificationDto) {
    // let userUiDto = await firstValueFrom(this.store.pipe(select(selectUserUiDto)));

    let sessionInfoDto = await firstValueFrom(this.store.pipe(select(selectSessionInfo)));
    let userUiDto = sessionInfoDto?.userUiDto;

    if (sessionInfoDto?.clientIp == dscNotificationDto.clientIp) {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'DSC inserted successfully' })
    }

    if (userUiDto?.userId == dscNotificationDto.userId) {
      if (dscNotificationDto.actionType == DscNotificationEnum.DSC_CONNECTION_CONNECTED) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'DSC connected successfully!' });
      } else if (dscNotificationDto.actionType == DscNotificationEnum.DSC_ADDED) {
        await this.userService.getUserDetails();
      } else if (dscNotificationDto.actionType == DscNotificationEnum.DSC_CONNECTION_DISCONNECTED) {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Utility connection disconnected!' });
      } else if (dscNotificationDto.actionType == DscNotificationEnum.DSC_VALIDATED) {
        let oldSessionInfoDto = await firstValueFrom(this.store.pipe(select(selectSessionInfo)));
        let sessionInfo = ApplicationUtils.deepClone(oldSessionInfoDto) as SessionInfoDto;
        sessionInfo.dscVerified = true;
        this.store.dispatch(SessionActions.saveSessionInfo({ sessionInfo }));
        this.router.navigate(["/Admin/"], { skipLocationChange: true });
      } else if (dscNotificationDto.actionType == DscNotificationEnum.INCORRECT_PIN) {
        this.userService.isCertificateVerified$.next('INCORRECT_PIN');
      }
      else if (dscNotificationDto.actionType == DscNotificationEnum.SIGN_PDF_ALERT) {
        let code = dscNotificationDto.data && dscNotificationDto.data['code'] as string;
        let message = dscNotificationDto.data && dscNotificationDto.data['message'] as string;

        if (code && message) {
          if (code == '400') {
            this.messageService.add({ severity: 'error', summary: 'Failed', detail: `Error: ${message}` });
          } else {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: message! });

            if (code == "201") {
              this.dscSignStatus$.next(true);
            }
          }
        } else {
          console.log("Message is blanked");
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.clearHeartbeat();
    if (this.socket) {
      this.socket.emit('unregister', { id: this.socket?.id, user: this.userUiDto?.primaryEmailId });
      this.socket.disconnect();
    }
  }

}
