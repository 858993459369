export enum DraftSubStatus {
  CONFIGURATION = "CONFIGURATION",
  ADDRESS = "ADDRESS",
  FEE = "FEE",
  CRITICAL_DATE = "CRITICAL_DATE",
  DOCUMENT = "DOCUMENT",
  APPROVALS = "APPROVALS",
  TECHNICAL = "TECHNICAL",
  FINANCIAL = "FINANCIAL",
  LOT = "LOT",
  COMPLETED = "COMPLETED"
}