import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, firstValueFrom, Observable, tap } from 'rxjs';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { PqFeeUiDto } from '../models/pcpq/PqFeeUiDto';
import { ApplicationConstants } from '../utils/ApplicationConstants';
import { ErrorService } from './error.service';
import { ThemeService } from './theme.service';
import { PqInitialUiDto } from '../models/pcpq/PqInitialUiDto';
import { PqConfigurationUiDto } from '../models/pcpq/PqConfigurationUiDto';
import { PqCriticalDatesUiDto } from '../models/pcpq/PqCriticalDatesUiDto';
import { PqAddressUiDto } from '../models/pcpq/PqAddressUiDto';
import { ApprovalWorkflowUiDto } from '../models/tenders/ApprovalWorkflowUiDto';
import { ApprovalWrapperUiDto } from '../models/tenders/ApprovalWrapperUiDto';
import { ContractAlgoEnum } from '../enums/ContractAlgoEnum';
import { QuestionResponseType } from '../enums/questionnaire/QuestionResponseType';
import { BidderEvaluationSummaryDataDto } from '../models/questionnaire/BidderEvaluationSummaryDataDto';
import { EvaluationTechnicalQuestionUiDto } from '../models/questionnaire/EvaluationTechnicalQuestionUiDto';
import { TechnicalConfigurationUiDto } from '../models/questionnaire/TechnicalConfigurationUiDto';
import { TechnicalDetailsWrapperUiDto } from '../models/questionnaire/TechnicalDetailsWrapperUiDto';
import { TechnicalQuestionWrapperUiDto } from '../models/questionnaire/TechnicalQuestionWrapperUiDto';
import { TechnicalSectionWrapperUiDto } from '../models/questionnaire/TechnicalSectionWrapperUiDto';
import { QuestionnaireActions } from '../state-management/questionnaire/questionnaire.actions';
import { MessageService } from 'primeng/api';
import { TenderSourcingEventWrapperUiDto } from '../models/tenders/TenderSourcingEventWrapperUiDto';
import { PqUiDto } from '../models/pcpq/PqUiDto';
import { DocumentArchiveUiDto } from '../models/DocumentArchiveUiDto';
import { pqDocumentsUiDto } from '../models/pcpq/PqDocumentsUiDto';
import { PqEvaluationWrapperDto } from '../models/pcpq/PqEvaluationWrapperDto';
import { PQActions } from '../state-management/pre_qualification/pq.actions';
import { PqSubmitDetailsUiDto } from '../models/pcpq/PqSubmitDetailsUiDto';
import { ApproveManageUiDto } from '../models/tenders/ApproveManageUiDto';
import { PqEvaluationRequestDto } from '../models/pcpq/PqEvaluationRequestDto';
import { PqRequestDto } from '../models/pcpq/PqRequestDto';
import { PqBidderEvaluationSummaryDataDto } from '../models/pcpq/PqBidderEvaluationSummaryDataDto';


@Injectable({
  providedIn: 'root'
})
export class PreQualificationService {

  private pqSubmitDetailsUiDto$ = new BehaviorSubject<PqSubmitDetailsUiDto>({});
  private pqSubmitDetailsUiDto: PqSubmitDetailsUiDto = {};
  private _pqDataDTO$ = new BehaviorSubject<TenderSourcingEventWrapperUiDto>({});
  private pqDataDTO: TenderSourcingEventWrapperUiDto = {};
  private _pqDocumentUiDTO$ = new BehaviorSubject<pqDocumentsUiDto>({});
  private pqDocumentConfigUiDto: pqDocumentsUiDto = {};
  private _pqAddressUiDTO$ = new BehaviorSubject<PqAddressUiDto>({});
  private _pqFeeUiDto$ = new BehaviorSubject<PqFeeUiDto>({});
  private _pqApprovalWrapperUiDto$ = new BehaviorSubject<ApprovalWrapperUiDto>({});
  private approvalWrapperUiDto: ApprovalWrapperUiDto = {};
  private _pqCriticalDateUiDto$ = new BehaviorSubject<PqCriticalDatesUiDto>({});
  private pqAddressConfigUiDto: PqAddressUiDto = {};
  private pqFeeConfigUiDto: PqFeeUiDto = {};
  private pqCriticalDateUiDto: PqCriticalDatesUiDto = {};
  private _pqConfigurationUiDTO$ = new BehaviorSubject<PqConfigurationUiDto>({});
  private pqConfigurationUiDto: PqConfigurationUiDto = {};


  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private themeService: ThemeService,
    private store: Store,
    private messageService: MessageService
  ) { }

  get getPqSubmitDetailsUiDto$() { return this.pqSubmitDetailsUiDto$.asObservable(); }
  get getPqFeeData$() { return this._pqFeeUiDto$.asObservable(); }
  get getPqCriticalDateData$() { return this._pqCriticalDateUiDto$.asObservable(); }
  get getPqApprovalsData$() { return this._pqApprovalWrapperUiDto$.asObservable(); }
  get getPqAddressData$() { return this._pqAddressUiDTO$.asObservable(); }
  get getPqData$() { return this._pqDataDTO$.asObservable(); }
  get getPqDocumentData$() { return this._pqDocumentUiDTO$.asObservable(); }

  public get pqConfigurationUiDTO$() { return this._pqConfigurationUiDTO$.asObservable(); }

  pqInitiate(pqData: PqInitialUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/pq/pq-initial`, pqData).pipe(
      tap(_ => console.log("Save PQ")),
      catchError(this.errorService.handleError<any>("Error while saving PQ")));
  }

  setPqSourcingEventWrapperUiDto(pqData: TenderSourcingEventWrapperUiDto) {
    this.pqDataDTO = pqData;
    this._pqDataDTO$.next(this.pqDataDTO);
  }

  //Get Pq list----------------------------
  async loadSourcingEventUiDto(pqRequestDto: PqRequestDto) {
    try {
      let apiResponseDto = await firstValueFrom(this.getAllSourcingEventList(pqRequestDto));
      console.log('apiResponseDtoEvent', apiResponseDto)
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setPqSourcingEventWrapperUiDto(apiResponseDto.data as TenderSourcingEventWrapperUiDto);
      } else {
        this.setPqSourcingEventWrapperUiDto({} as TenderSourcingEventWrapperUiDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

    //Get Pq financial Data----------------------------
    async getPqValidation(pqId: string) {
      try {
        let apiResponseDto = await firstValueFrom(this.fetchPqValidateData(pqId));
  
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.messageService.add({ severity: 'success', summary: 'Validation Success', detail: apiResponseDto.message});
          this.setFinalData(apiResponseDto.data as PqSubmitDetailsUiDto)
        }else{
          this.messageService.add({ severity: 'error', summary: 'Validation Error', detail: apiResponseDto.message});
        }
      } catch (error) {
        console.error(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error'});
      }
    }
   setFinalData(pqSubmitDetailsUiDto: PqSubmitDetailsUiDto) {
      this.pqSubmitDetailsUiDto = pqSubmitDetailsUiDto;
      this.pqSubmitDetailsUiDto$.next(this.pqSubmitDetailsUiDto);
    }


  fetchPqValidateData(pqId: string): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`pq/${pqId}/pq-submit-validation`,{}).pipe(
      tap(_ => console.log("Fetch pq list")),
      catchError(this.errorService.handleError<any>("Error while fetching pq configdata")));
  }
  
    //Save pq  data--------------------------
    saveFinalSubmit(pqSubmitDetailsUiDto: PqSubmitDetailsUiDto): Observable<ServerAPIResponseDto> {
      return this.http.post<ServerAPIResponseDto>(`pq/pq-submit-details`, pqSubmitDetailsUiDto).pipe(
        tap(_ => console.log("Submited pq ")),
        catchError(this.errorService.handleError<any>("Error while saving pq")));
    }
    
    //---------------------------------------------------
  pqApproval(approveManageUiDto :ApproveManageUiDto ): Observable<ServerAPIResponseDto>{
    return this.http.post<ServerAPIResponseDto>(`pq/manage-approval`,approveManageUiDto).pipe(
      tap(_ => console.log("Pq Updated")),
      catchError(this.errorService.handleError<any>("Error while updating the pq")));
  }
  
  rejectedDraftToDraft(pqId:string){
  return this.http.post<ServerAPIResponseDto>(`pq/${pqId}/markPqDraft`,{}).pipe(
    tap(_ => console.log("Pq Updated successfully")),
    catchError(this.errorService.handleError<any>("Error while updating the pq")));
  }


  getAllSourcingEventList(pqRequestDto: PqRequestDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/pq/pq`, pqRequestDto).pipe(
      tap(_ => console.log("Fetch pq list")),
      catchError(this.errorService.handleError<any>("Error while fetching pq list")));
  }

  async getPqCriticalDate(pqId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchPqCriticalDate(pqId));
      //console.log('apiResponseDtoEvent', apiResponseDto)
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setCriticalDateData(apiResponseDto.data as PqCriticalDatesUiDto)
      }
    } catch (error) {
      console.error(error);
    }
  }



  async loadPqUiDtoByPqId(pqId: string) {

    try {
      let apiResponseDto = await firstValueFrom(this.fetchPqUiDto(pqId!));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let pqUiDto = apiResponseDto.data as PqUiDto;
        this.store.dispatch(PQActions.setCurrentPqUiDto({ pqUiDto }));
      } else {
        console.error(apiResponseDto.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  fetchPqUiDto(pqId: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`/pq/${pqId}/pq-details`).pipe(
      tap(_ => console.log("Get pq")),
      catchError(this.errorService.handleError<any>("Error while getting pq")));
  }

  setCriticalDateData(pqCriticalDateData: PqCriticalDatesUiDto) {
    this.pqCriticalDateUiDto = pqCriticalDateData;
    this._pqCriticalDateUiDto$.next(this.pqCriticalDateUiDto);
  }
  fetchPqCriticalDate(pqId: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`pq/${pqId}/critical-dates`).pipe(
      tap(_ => console.log("Fetch pq list")),
      catchError(this.errorService.handleError<any>("Error while fetching pq configdata")));
  }

  //Get pq Fee Data----------------------------
  async getPqFee(pqId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchPqFee(pqId));
      //console.log('apiResponseDtoEvent', apiResponseDto)
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setFeeData(apiResponseDto.data as PqFeeUiDto)
      }
    } catch (error) {
      console.error(error);
    }
  }
  setFeeData(pqFeeData: PqFeeUiDto) {
    this.pqFeeConfigUiDto = pqFeeData;
    this._pqFeeUiDto$.next(this.pqFeeConfigUiDto);
  }
  fetchPqFee(pqId: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`pq/${pqId}/fee`).pipe(
      tap(_ => console.log("Fetch pq list")),
      catchError(this.errorService.handleError<any>("Error while fetching pq configdata")));
  }

  //---------------------------------------------------

  //Save PQ Fee data--------------------------
  savePqFee(PqFeeUiDto: PqFeeUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/pq/fee`, PqFeeUiDto).pipe(
      tap(_ => console.log("Save pq Fee")),
      catchError(this.errorService.handleError<any>("Error while saving pq")));
  }





  async getPqConfiguration(pqId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchPqConfiguration(pqId));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setConfigurationData(apiResponseDto.data as PqConfigurationUiDto)
      }
    } catch (error) {
      console.error(error);
    }
  }

  setConfigurationData(pqConfigurationUiDto: PqConfigurationUiDto) {
    this.pqConfigurationUiDto = pqConfigurationUiDto;
    this._pqConfigurationUiDTO$.next(this.pqConfigurationUiDto);
  }

  fetchPqConfiguration(pqId: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`pq/${pqId}/configuration`).pipe(
      tap(_ => console.log("Fetch Pq Data")),
      catchError(this.errorService.handleError<any>("Error while fetching pq configurationdata")));
  }


  savePqConfiguration(pqConfigurationUiDto: PqConfigurationUiDto) {
    return this.http.post<ServerAPIResponseDto>(`/pq/configuration`, pqConfigurationUiDto).pipe(
      tap(_ => console.log("Save Pq Configuration")),
      catchError(this.errorService.handleError<any>("Error while saving pq")));
  }

  setAddressData(pqAddressData: PqAddressUiDto) {
    if (pqAddressData) {
      this.pqAddressConfigUiDto = pqAddressData;
      this._pqAddressUiDTO$.next(this.pqAddressConfigUiDto);
    }

  }

  clearAddressData() {
    this.pqAddressConfigUiDto = {};
    this._pqAddressUiDTO$.next(this.pqAddressConfigUiDto);
  }

  fetchPqAddress(pqId: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`pq/${pqId}/address`).pipe(
      tap(_ => console.log("Fetch pq list")),
      catchError(this.errorService.handleError<any>("Error while fetching pq configdata")));
  }

  //Save Pq address data--------------------------
  savePqAddress(PqAddressUiDto: PqAddressUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/pq/address`, PqAddressUiDto).pipe(
      tap(_ => console.log("Save pq address")),
      catchError(this.errorService.handleError<any>("Error while saving pq")));
  }

  async getPqAddress(pqId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchPqAddress(pqId));
      //console.log('apiResponseDtoEvent', apiResponseDto)
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setAddressData(apiResponseDto.data as PqAddressUiDto)
      }
    } catch (error) {
      console.error(error);
    }
  }

  savePqCriticalDate(pqCriticalDateUiDto: PqCriticalDatesUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/pq/critical-dates`, pqCriticalDateUiDto).pipe(
      tap(_ => console.log("Save pq CriticalDate")),
      catchError(this.errorService.handleError<any>("Error while saving pq")));
  }



  //Get pq Approvals Data----------------------------
  async getPqApprovals(pqId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchPqApprovals(pqId));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setApprovalsData(apiResponseDto.data as ApprovalWrapperUiDto)
      }
    } catch (error) {
      console.error(error);
    }
  }

  setApprovalsData(approvalWrapperUiDto: ApprovalWrapperUiDto) {
    this.approvalWrapperUiDto = approvalWrapperUiDto;
    this._pqApprovalWrapperUiDto$.next(this.approvalWrapperUiDto);
  }

  fetchPqApprovals(pqId: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`pq/${pqId}/approvals`).pipe(
      tap(_ => console.log("Fetch pq list")),
      catchError(this.errorService.handleError<any>("Error while fetching pq configdata")));
  }

  //Update Pq status document to Approval data--------------------------  
  // updatePqNextStep(pqId: string, status: string): Observable<ServerAPIResponseDto> {
  //   return this.http.post<ServerAPIResponseDto>(`pq/${pqId}/documents/${status}`, {}).pipe(
  //     tap(_ => console.log("Save pq document")),
  //     catchError(this.errorService.handleError<any>("Error while saving pq")));
  // }

  //---------------------------------------------------

  //Save Pq Approvals data--------------------------
  savePqApprovals(PqApprovalsUiDto: ApprovalWorkflowUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/pq/approvals`, PqApprovalsUiDto).pipe(
      tap(_ => console.log("Save pq Approvals")),
      catchError(this.errorService.handleError<any>("Error while saving pq")));
  }

  updateQuestionnaireDetails(questionnaireDetailsUiDto: TechnicalDetailsWrapperUiDto) {
    this.store.dispatch(QuestionnaireActions.setQuestionnaireDetailsUiDto({ technicalDetailsWrapperUiDto: questionnaireDetailsUiDto }));
  }

  updatePqEvaluationData(pqEvaluationWrapperDto: PqEvaluationWrapperDto) {
     this.store.dispatch(QuestionnaireActions.setPqEvaluationWrapperDto({  pqEvaluationWrapperDto }));
  }
  delelePqDocument(pqId: string, fileId: string): Observable<ServerAPIResponseDto> {
    return this.http.delete<ServerAPIResponseDto>(`/pq/${pqId}/documents/${fileId}`).pipe(
      tap(_ => console.log("download pq document")),
      catchError(this.errorService.handleError<any>("Error while saving pq")));
  }

  async loadPqQuestionnaireDetails(pqId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchPqQuestionnaireDetails(pqId));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.updateQuestionnaireDetails(apiResponseDto.data as TechnicalDetailsWrapperUiDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async toggleTechnicalScore(pqId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.changeTechnicalScore(pqId));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.updateQuestionnaireDetails(apiResponseDto.data as TechnicalDetailsWrapperUiDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadPqEvaluationData(pqEvaluationRequestDto: PqEvaluationWrapperDto) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchPqEvaluationData(pqEvaluationRequestDto));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.updatePqEvaluationData(apiResponseDto.data as PqEvaluationWrapperDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  runTechnicalQuestionValidation(
    technicalDetailsWrapperUiDto: TechnicalDetailsWrapperUiDto,
    tenderOrdering: ContractAlgoEnum,
    currentSectionId?: string,
  ): boolean {
    const technicalConfigurationUiDto = technicalDetailsWrapperUiDto.technicalConfigurationUiDto;
    const technicalSectionUiDtoList = technicalDetailsWrapperUiDto.technicalSectionUiDtoList || [];
    const allTechnicalQuestionUiDtos = technicalDetailsWrapperUiDto.technicalQuestionUiDtos || [];

    // Config Validations
    const scored = technicalConfigurationUiDto?.scored || false;
    const totalScore = technicalConfigurationUiDto?.totalScore || 0;
    const qualifyingScore = technicalConfigurationUiDto?.qualifyingScore || 0;
    const technicalWeightage = technicalConfigurationUiDto?.technicalWeightage || 0;
    const financialWeightage = technicalConfigurationUiDto?.financialWeightage || 0;

    if (scored) {
      if (totalScore < 1 || qualifyingScore < 1) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill technical config first!' });
        return true;
      }

      if (qualifyingScore > totalScore) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Qualifying score should not be greater than maximum score.' });
        return true;
      }
    }

    if (tenderOrdering == ContractAlgoEnum.QCBS) {
      if (technicalWeightage < 1 || financialWeightage < 1) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill technical config first!' });
        return true;
      }

      if ((technicalWeightage + financialWeightage) != 100) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'The sum of technical and financial weightage must be equal to 100%.' });
        return true;
      }
    }

    // Section Validations
    if (technicalSectionUiDtoList.length == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please create at least one section.' });
      return true;
    }

    let totalSectionsScore = technicalSectionUiDtoList.reduce((prev, curr) => prev + Number(curr.score || 0), 0);

    if (scored && totalScore != totalSectionsScore) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Total section scores and total score is mismatch!' });
      return true;
    }

    // Question Validations
    if (allTechnicalQuestionUiDtos.length == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please create at least one questions.' });
      return true;
    }

    if (currentSectionId) {
      if (this.questionsValidationOfSection(technicalDetailsWrapperUiDto, scored, currentSectionId)) {
        return true;
      }
    } else {
      for (let i = 0; i < technicalSectionUiDtoList.length; i++) {
        const section = technicalSectionUiDtoList[i];
        if (this.questionsValidationOfSection(technicalDetailsWrapperUiDto, scored, section.sectionId!)) {
          return true;
        }
      }
    }

    return false;
  }

  private questionsValidationOfSection(technicalDetailsWrapperUiDto: TechnicalDetailsWrapperUiDto, scored: boolean, sectionId: string, showToast: boolean = true) {
    const technicalSectionUiDtoList = technicalDetailsWrapperUiDto.technicalSectionUiDtoList || [];
    const allTechnicalQuestionUiDtos = technicalDetailsWrapperUiDto.technicalQuestionUiDtos || [];

    const section = technicalSectionUiDtoList.find(item => item.sectionId == sectionId)!;

    const technicalQuestionUiDtos = allTechnicalQuestionUiDtos.filter(item => item.sectionId == sectionId);
    let totalQuestionsScore = technicalQuestionUiDtos.reduce((prev, curr) => prev + Number(curr.score || 0), 0);

    if (technicalQuestionUiDtos.length == 0) {
      if (showToast) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill questions in every section!' });
      }
      return true;
    }

    if (scored && Number(section.score || 0) != totalQuestionsScore) {
      if (showToast) {
        this.messageService.add({ severity: 'error', summary: `Section - ${section.sequenceNo}`, detail: 'Total questions scores and its section score is mismatch!' });
      }
      return true;
    }

    for (let i = 0; i < technicalQuestionUiDtos.length; i++) {
      const techQuestion = technicalQuestionUiDtos[i];
      if (!techQuestion.questionText) {
        if (showToast) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please type question text of question ' + techQuestion.sequenceNo });
        }
        return true;
      }
      if (!techQuestion.responseType) {
        if (showToast) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select response type of question ' + techQuestion.sequenceNo });
        }
        return true;
      }
      if (techQuestion.responseType == QuestionResponseType.SINGLE_CHOICE || techQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
        if (!techQuestion.questionOptions || techQuestion.questionOptions.length < 2) {
          if (showToast) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please add minimum 2 response choices in question ' + techQuestion.sequenceNo });
          }
          return true;
        }

        if (scored && techQuestion.responseType == QuestionResponseType.SINGLE_CHOICE) {
          let optionsScores = techQuestion.questionOptions.sort((a, b) => Number(b.questionOptionScore || 0) - Number(a.questionOptionScore || 0));
          if (techQuestion.score != optionsScores[0].questionOptionScore) {
            if (showToast) {
              this.messageService.add({ severity: 'error', summary: `Question - ${techQuestion.sequenceNo}`, detail: 'Total options score and its question score is mismatch!' });
            }
            return true;
          }
        }
        if (scored && techQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
          let optionsScore = techQuestion.questionOptions.reduce((prev, curr) => prev + Number(curr.questionOptionScore || 0), 0);
          if (techQuestion.score != optionsScore) {
            if (showToast) {
              this.messageService.add({ severity: 'error', summary: `Question - ${techQuestion.sequenceNo}`, detail: 'Total options score and its question score is mismatch!' });
            }
            return true;
          }
        }
      }
    }

    return false;
  }
  savePqDocument(pqDocumentsUiDto: DocumentArchiveUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/pq/documents`, pqDocumentsUiDto).pipe(
      tap(_ => console.log("Save pq document")),
      catchError(this.errorService.handleError<any>("Error while saving pq")));
  }

  async getPqDocument(pqId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchPqDocument(pqId));
      //console.log('apiResponseDtoEvent', apiResponseDto)
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setDocumentData(apiResponseDto.data as pqDocumentsUiDto)
      }
    } catch (error) {
      console.error(error);
    }
  }

  setDocumentData(pqDocumentData: pqDocumentsUiDto) {
    this.pqDocumentConfigUiDto = pqDocumentData;
    this._pqDocumentUiDTO$.next(this.pqDocumentConfigUiDto);
  }
  fetchPqDocument(pqId: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`pq/${pqId}/documents`).pipe(
      tap(_ => console.log("Fetch pq list")),
      catchError(this.errorService.handleError<any>("Error while fetching pq configdata")));
  }

 //Update Pq status document to Approval data--------------------------  
  updatePqNextStep(pqId: string, status: string): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`pq/${pqId}/documents/${status}`, {}).pipe(
      tap(_ => console.log("Save pq document")),
      catchError(this.errorService.handleError<any>("Error while saving pq")));
  }

  //Dowlload Document---------------------------
  downloadPqDocument(fileId: string) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers
    };
    return this.http.get<any>('/blob/downloadBlob?fileId=' + fileId, httpOptions);
  }

  //Delete Document---------------------------
  delelepqDocument(pqId: string, fileId: string): Observable<ServerAPIResponseDto> {
    return this.http.delete<ServerAPIResponseDto>(`/pq/${pqId}/documents/${fileId}`).pipe(
      tap(_ => console.log("download pq document")),
      catchError(this.errorService.handleError<any>("Error while saving pq")));
  }


  getValidSectionIds(technicalDetailsWrapperUiDto: TechnicalDetailsWrapperUiDto): string[] {
    const technicalConfigurationUiDto = technicalDetailsWrapperUiDto.technicalConfigurationUiDto;
    const technicalSectionUiDtoList = technicalDetailsWrapperUiDto.technicalSectionUiDtoList || [];

    const scored = technicalConfigurationUiDto?.scored || false;

    let sections: string[] = technicalSectionUiDtoList.map(section => {
      const isInvalid = this.questionsValidationOfSection(technicalDetailsWrapperUiDto, scored, section.sectionId!, false);
      return !isInvalid ? section.sectionId! : undefined;
    }).filter((sectionId): sectionId is string => sectionId !== undefined);

    return sections;
  }

  private changeTechnicalScore(pqId: string): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/pq/${pqId}/technical-score`, null).pipe(
      tap(_ => console.log(`Changing technical score of pq ${pqId}`)),
      catchError(this.errorService.handleError<any>(`Error while changing technical score of pq ${pqId}`)));
  }

  private fetchPqQuestionnaireDetails(pqId: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`/pq/${pqId}/pq-technical-details`).pipe(
      tap(_ => console.log(`Getting questionnaire details of pqId ${pqId}`)),
      catchError(this.errorService.handleError<any>(`Error while getting questionnaire details of pq ${pqId}`)));
  }

  saveTechnicalConfiguration(technicalConfigurationUiDto: TechnicalConfigurationUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/pq/technical-configuration`, technicalConfigurationUiDto).pipe(
      tap(_ => console.log(`Saving questionnaire of pq ${technicalConfigurationUiDto.tenderId}`)),
      catchError(this.errorService.handleError<any>(`Error while saving questionnaire of pq ${technicalConfigurationUiDto.tenderId}`)));
  }

  saveTenderSectionWrapperUiDto(sectionWrapperUiDto: TechnicalSectionWrapperUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/pq/technical-sections`, sectionWrapperUiDto).pipe(
      tap(_ => console.log(`Saving questionnaire of pq ${sectionWrapperUiDto.tenderId}`)),
      catchError(this.errorService.handleError<any>(`Error while saving questionnaire of pq ${sectionWrapperUiDto.tenderId}`)));
  }

  saveTenderTechQuestionWrapperUiDto(technicalQuestionWrapperUiDto: TechnicalQuestionWrapperUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/pq/technical-questions`, technicalQuestionWrapperUiDto).pipe(
      tap(_ => console.log(`Saving questionnaire of pq ${technicalQuestionWrapperUiDto.tenderId}`)),
      catchError(this.errorService.handleError<any>(`Error while saving questionnaire of pq ${technicalQuestionWrapperUiDto.tenderId}`)));
  }

  saveTechnicalDetailsWrapperUiDto(technicalDetailsWrapperUiDto: TechnicalDetailsWrapperUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/pq/pq-technical-details`, technicalDetailsWrapperUiDto).pipe(
      tap(_ => console.log(`Saving questionnaire of pq ${technicalDetailsWrapperUiDto.tenderId}`)),
      catchError(this.errorService.handleError<any>(`Error while saving questionnaire of pq ${technicalDetailsWrapperUiDto.tenderId}`)));
  }

  deleteTechnicalSection(pqId: string, sectionId: string): Observable<ServerAPIResponseDto> {
    return this.http.delete<ServerAPIResponseDto>(`pq/${pqId}/technical-sections/${sectionId}`).pipe(
      tap(_ => console.log(`Deleting section of pq ${pqId}`)),
      catchError(this.errorService.handleError<any>(`Error while deleting section of pq ${pqId}`)));
  }

  deleteTechnicalQuestion(pqId: string, questionId: string): Observable<ServerAPIResponseDto> {
    return this.http.delete<ServerAPIResponseDto>(`pq/${pqId}/technical-questions/${questionId}`).pipe(
      tap(_ => console.log(`Deleting question of pq ${pqId}`)),
      catchError(this.errorService.handleError<any>(`Error while deleting question of pq ${pqId}`)));
  }

  // Evaluation
  fetchPqEvaluationData(pqEvaluationRequestDto: PqEvaluationRequestDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/pq-evaluation/data`, pqEvaluationRequestDto).pipe(
      tap(_ => console.log(`Getting evaluation data of pq ${pqEvaluationRequestDto.pqId}`)),
      catchError(this.errorService.handleError<any>(`Error while getting evaluation data of pq ${pqEvaluationRequestDto.pqId}`)));
  }

  startPqEvaluation(bidderEvaluationSummary: PqBidderEvaluationSummaryDataDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/pq-evaluation/user-evaluation`, bidderEvaluationSummary).pipe(
      tap(_ => console.log(`Start evaluation of user id ${bidderEvaluationSummary.evaluatorUserId}`)),
      catchError(this.errorService.handleError<any>(`Error while starting evaluation of user id ${bidderEvaluationSummary.evaluatorUserId}`)));
  }

  getUserEvaluationDetails(pqId: string, userId: string): Observable<ServerAPIResponseDto> {
    let params = new HttpParams().set('pqId', pqId).set('userId', userId);
    return this.http.get<ServerAPIResponseDto>(`/pq-evaluation/user-evaluation-details`, { params }).pipe(
      tap(_ => console.log(`Get evaluation data of pq id ${pqId}`)),
      catchError(this.errorService.handleError<any>(`Error while getting evaluation data of pq id ${pqId}`)));
  }

  updateTechQuestionEvaluation(evaluationTechnicalQuestionUiDto: EvaluationTechnicalQuestionUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/pq-evaluation/questions`, evaluationTechnicalQuestionUiDto).pipe(
      tap(_ => console.log(`Update evaluation data of question id ${evaluationTechnicalQuestionUiDto.questionId}`)),
      catchError(this.errorService.handleError<any>(`Error while submitting evaluation data of question id ${evaluationTechnicalQuestionUiDto.questionId}`)));
  }

  getComparisonSheetItemRate(pqId: string, removeDisqualify: boolean) {
    let params = new HttpParams().set('pqId', pqId).set('removeDisqualify', `${removeDisqualify}`);

    return this.http.get<ServerAPIResponseDto>(`/pq-evaluation/cs-item-rate`, { params }).pipe(
      tap(_ => console.log(`Get cs-item-rate of pq id ${pqId}`)),
      catchError(this.errorService.handleError<any>(`Error while getting cs-item-rate of pq id ${pqId}`)));
  }

  getComparisonSheetItemWise(pqId: string, removeDisqualify: boolean) {
    let params = new HttpParams().set('pqId', pqId).set('removeDisqualify', `${removeDisqualify}`);

    return this.http.get<ServerAPIResponseDto>(`/pq-evaluation/cs-item-wise`, { params }).pipe(
      tap(_ => console.log(`Get cs-item-wise of pq id ${pqId}`)),
      catchError(this.errorService.handleError<any>(`Error while getting cs-item-wise of pq id ${pqId}`)));
  }

  downloadComparisonSheetItemWise(pqId: string, fileType: 'pdf' | 'excel') {
    const params = new HttpParams().set('pqId', pqId).set('fileType', fileType);
    const headers = new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      responseType: 'arraybuffer' as 'json',
      headers: headers,
      params: params
    };

    return this.http.get<any>('/pq-evaluation/cs-item-wise-report', httpOptions).pipe(
      tap(_ => console.log(`Get cs-item-wise of pq id ${pqId}`)),
      catchError(this.errorService.handleError<any>(`Error while getting cs-item-wise of pq id ${pqId}`)));
  }

  downloadComparisonSheetItemRate(pqId: string, fileType: 'pdf' | 'excel') {
    const params = new HttpParams().set('pqId', pqId).set('fileType', fileType).set('removeDisqualify', 'false');
    const headers = new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      responseType: 'arraybuffer' as 'json',
      headers: headers,
      params: params,
    };

    return this.http.get<any>('/pq-evaluation/cs-item-rate-report', httpOptions).pipe(
      tap(() => console.log(`Successfully fetched comparison sheet item rate for pq ID: ${pqId}`)),
      catchError(this.errorService.handleError<any>(`Failed to fetch comparison sheet item rate for pq ID: ${pqId}`))
    );
  }

}
