import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { SessionActions } from './session.actions';
import { SessionInfoDto } from '../../models/SessionInfoDto';
import { ApplicationUtils } from '../../utils/ApplicationUtils';

interface State {
  sessionInfo: SessionInfoDto | null;
}

const initialState: State = {
  sessionInfo: null
};

export const sessionFeature = createFeature({
  name: 'session',
  reducer: createReducer(
    initialState,
    on(SessionActions.saveSessionInfo, (state, { sessionInfo }) => ({
      ...state,
      sessionInfo
    })),
    on(SessionActions.getSessionInfo, (state) => ({
      ...state
    })),
    on(SessionActions.updateUserUiDto, (state, { userUiDto }) => ({
      ...state,
      sessionInfo: {
        ...state.sessionInfo,
        userUiDto
      }
    })),
    on(SessionActions.clearSession, (state) => ({
      ...state,
      sessionInfo: null
    }))
  ),
  extraSelectors: ({ selectSessionInfo }) => {
    const selectUserUiDto = createSelector(
      selectSessionInfo, (sessionInfoDto) => sessionInfoDto?.userUiDto
    );

    const selectDscCertificates = createSelector(
      selectUserUiDto, (bidderUiDto) => bidderUiDto?.dscCertificateDtos ?? []
    );

    const selectEncryptionCertificate = createSelector(
      selectDscCertificates, (certificates) => certificates.find(cert => ApplicationUtils.checkIfCertificateIsForEncryption(cert.keyType!))
    );

    const selectSigningCertificate = createSelector(
      selectDscCertificates, (certificates) => certificates.find(cert => !ApplicationUtils.checkIfCertificateIsForEncryption(cert.keyType!))
    );

    return { selectUserUiDto, selectDscCertificates, selectEncryptionCertificate, selectSigningCertificate }
  },
});

export const {
  name, // feature name
  reducer, // feature reducer
  selectSessionState, // feature selector
  selectSessionInfo, // selector for `sessionInfoDto` property
  selectUserUiDto,   // selector for `userUiDto` property
  selectDscCertificates,
  selectEncryptionCertificate,
  selectSigningCertificate
} = sessionFeature;