import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { OrganizationWrapperUiDto } from '../../models/OrganizationWrapperUiDto';
import { UserUiDto } from '../../models/UserUiDto';
import { AuthenticationService } from '../../services/authentication.service';
import { Pattern } from '../../utils/Patterns';
import { PasswordStrengthValidator } from '../../validators/password-strength.validators';
import { selectUserUiDto } from '../../state-management/session/session.features';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  organizationUiDto?: OrganizationWrapperUiDto;
  userUiDto?: UserUiDto ;

  headerName: 'DEFAULT' | 'ONBOARDING' = 'DEFAULT';
  onboardingPages = ['basicDetails', 'dscDetails']

  userSubscription$?: Subscription;
  routerSubscription?: Subscription;

  menuItems = [
    { title: 'Edit', escape: false },
  ]

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private store: Store,
    private activatedRoute: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {

    this.userSubscription$ = this.store.pipe(select(selectUserUiDto)).subscribe(userUiDto => {
      if (userUiDto) {
        this.userUiDto = userUiDto;
      } else {
        this.userUiDto = undefined;
      }
    })
    this.routerSubscription = this.router.events.subscribe(() => {
      this.checkHeaderVisibility();
    });
  }

  checkHeaderVisibility() {
    // Get the current active route path
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    // Now, route should be the last active route
    let currentPath = route.snapshot.routeConfig?.path || '';
    // console.log('Current Path:', currentPath);

    // Set the visibility of the header based on the path
    if (this.onboardingPages.includes(currentPath)) {
      this.headerName = 'ONBOARDING';
    } else {
      this.headerName = 'DEFAULT';
    }
  }

  logout() {
    this.authService.logout();
  }
  navigateToForgotPassword() {
    this.router.navigate(["/resetPassword"], { skipLocationChange: true })
  }
  ngOnDestroy(): void {
   
    if (this.userSubscription$) {
      this.userSubscription$.unsubscribe();
    }
  }

}
