import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, Subscription } from 'rxjs';
import { UserService } from './shared/services/user.service';
import { selectSessionInfo, selectUserUiDto } from './shared/state-management/session/session.features';
import { AuthenticationService } from './shared/services/authentication.service';
import { AdminService } from './shared/services/admin.service';
import { SessionInfoDto } from './shared/models/SessionInfoDto';
import { UserUiDto } from './shared/models/UserUiDto';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnDestroy, AfterViewInit {
  title = 'procurext-ai-admin-ui';

  isLoggedIn: boolean = false;
  isShowHeader: boolean = true;

  routerSubscription?: Subscription;
  userUiDtoSubscription$?: Subscription;

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private adminService: AdminService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
  
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log("Current Url :-"+ window.location.href);
        // Scroll to top on navigation
        window.scrollTo(0, 0);

        this.checkHeaderVisibility();
      }
    });

    this.userUiDtoSubscription$ = this.store.pipe(select(selectUserUiDto)).subscribe(userUiDto => {
      if (userUiDto) {
        this.isLoggedIn = userUiDto != null;
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.disableAngularLoader();
    this.adminService.loadDeploymentConfiguration();
    let sessionId = localStorage.getItem("AUC_SESSION_ID");
    let isOnboardingUrl = location.pathname.includes('/onboarding');

    if (sessionId && !isOnboardingUrl) {
      // redirect to new intermediate page
      this.router.navigate(["/intermediate"], { skipLocationChange: true })

      this.handleRedirectionWithSessionId();
    }
  }

  private disableAngularLoader() {
    let element = document.getElementById('idForDataLoader');
    if (element) {
      element.style.display = 'none';
      console.log("Loader disable successfully.")
    }
  }

  private async handleRedirectionWithSessionId() {
    await this.userService.getUserSessionInfo();
    const sessionInfoDto = await firstValueFrom(this.store.pipe(select(selectSessionInfo))) as SessionInfoDto;

    let userUiDto: UserUiDto | undefined = sessionInfoDto?.userUiDto;

    if (userUiDto && userUiDto.userId) {
      this.authenticationService.initializeListenerAndDoRouting(sessionInfoDto);
    } else {
      this.router.navigate(['/'], { skipLocationChange: true });
    }

    // if (sessionInfoDto) {
    //   await this.dataRedirectionService.initializeUserListeners();
    // }
    // this.appLoader?.closeLoaderIcon(ApplicationConstants.LOADER_NOTIFICATION);
    // this.dataRedirectionService.navigateToPage();

  }

  checkHeaderVisibility() {
    // Get the current active route path
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    // Now, route should be the last active route
    let currentPath = route.snapshot.routeConfig?.path || '';
    console.log('Current Path:', currentPath);

    // Set the visibility of the header based on the path
    if (currentPath === 'verifyDsc') {
      this.isShowHeader = false;
    } else {
      this.isShowHeader = true;
    }
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.userUiDtoSubscription$) {
      this.userUiDtoSubscription$.unsubscribe();
    }
  }
}
