import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { UserTokenDto } from '../models/SessionInfoDto';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class JwtRefreshService {

  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorService
  ) { }


  refreshToken(): Observable<any> {
    const localUserTokenDto = localStorage.getItem('userTokenDto');
    const userTokenDto = localUserTokenDto ? JSON.parse(localUserTokenDto) as UserTokenDto : undefined;

    const params = new HttpParams().set('refreshToken', userTokenDto?.refreshToken || '');

    return this.httpClient.post<any>('auth/refresh', null, { params, observe: 'response' }).pipe(
      map(apiResponseDto => {
        if (apiResponseDto.status == 200) {
          const userTokenDto = apiResponseDto.body.jwtData as UserTokenDto;
          localStorage.setItem('userTokenDto', JSON.stringify(userTokenDto));

          return userTokenDto;  // Return the updated token
        } else {
          throw new Error("Token refresh failed");
        }
      }),
      catchError(this.errorService.handleError<any>('Token refresh failed'))
    );
  }

}
