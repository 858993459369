import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { TenderUiDto } from "../../models/tenders/TenderUiDto";
import { SourcingEventUiDto } from "../../models/tenders/SourcingEventUiDto";

export const TenderManagementActions = createActionGroup({
  source: 'TenderManagement',
  events: {
    'Set Current Sourcing Event Ui Dto': props<{sourcingEventUiDto: SourcingEventUiDto }>(),
    'Get Sourcing Event Ui Dto': emptyProps(),

    'Set Current Tender Ui Dto': props<{ tenderUiDto: TenderUiDto }>(),
    'Get Current Tender Ui Dto': emptyProps(),
    'Clear Current Tender Ui Dto': emptyProps(),

    'Get Current Tender Opportunity Wrapper Dto': emptyProps(),
    'Clear Current Tender Opportunity Wrapper Dto': emptyProps(),

    'Get All Admin Users': emptyProps(),
  }
})