import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { io, Socket } from 'socket.io-client';
import { UserUiDto } from '../models/UserUiDto';
import { firstValueFrom } from 'rxjs';
import { OrganizationWrapperUiDto } from '../models/OrganizationWrapperUiDto';
import { OnboardingActions } from '../state-management/onboarding/onboarding.actions';
import { selectTenderUiDto } from '../state-management/tender/tender.features';
import { CacheUpdateTypes } from '../utils/CacheUpdateTypes';
import { TCTenderServiceService } from 'src/app/layouts/tender/tender-create/services/tender-service.service';
import { selectOrganizationUiDto } from '../state-management/onboarding/onboarding.features';
import { selectAuctionUiDto } from '../state-management/auction/auction.features';
import { AuctionService } from 'src/app/layouts/Auction/auction.service';
import { selectPqUiDto } from '../state-management/pre_qualification/pq.features';
import { PreQualificationService } from './pre-qualification.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService implements OnDestroy {
  private socket?: Socket;
  private serverUrl = '';
  private reconnectInterval = 3000; // 2 seconds
  private socketPingInterval = 30000; // 30 seconds
  private heartbeatInterval?: any;

  userUiDto?: UserUiDto;

  constructor(
    private store: Store,
    private tenderService: TCTenderServiceService,
    private pqService: PreQualificationService,
    private auctionService: AuctionService
  ) {
    this.handleVisibilityChange();
  }

  private async connectSocket() {
    this.socket = io(`wss://${this.serverUrl}?email=${this.userUiDto?.primaryEmailId}`, {
      transports: ['websocket'], // Ensure WebSocket is the primary transport
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000, // Delay in milliseconds
      autoConnect: true,
    });
  }

  async connect(serverUrl: string, userUiDto: UserUiDto) {
    this.serverUrl = serverUrl;
    this.userUiDto = userUiDto;

    await this.connectSocket();

    this.socket?.on('connect', () => {
      console.log('Socket connected to Socket.IO server');
      this.emitMessage('register', { email: this.userUiDto?.primaryEmailId });

      // Start the heartbeat if it hasn't been started yet
      if (!this.heartbeatInterval) {
        this.startHeartbeat();
      }
    });

    this.socket?.on('disconnect', (reason) => {
      console.warn('Socket disconnected:', reason);
      this.emitMessage('unregister', { email: this.userUiDto?.primaryEmailId });

      setTimeout(() => {
        if (!this.socket) this.connectSocket(); // Reconnect if the socket is not already connected
      }, this.reconnectInterval); // Reconnect on error
    });

    this.socket?.on('connect_error', (error) => {
      console.warn('Socket connection error:', error.message);

      setTimeout(() => {
        if (!this.socket) this.connectSocket(); // Reconnect if the socket is not already connected
      }, this.reconnectInterval); // Reconnect on error
    });

    this.socket?.on('reconnect_attempt', () => {
      console.log('Socket reconnecting...');
    });

    this.socket?.on('notification', (message) => {
      console.log(message);

      const { id, data } = message;
      this.acknowledgeNotification(id);
      this.handleRedisCacheUpdates(JSON.parse(data));
    })

    this.socket?.on('pong', (message) => {
      console.log(message);
    })
  }

  private emitMessage(event: string, data: any) {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }

  private startHeartbeat() {
    this.heartbeatInterval = setInterval(() => {
      if (this.socket && this.socket.connected) {
        this.emitMessage('ping', {
          email: this.userUiDto?.primaryEmailId,
          timestamp: new Date().toISOString()
        }); // Send a ping message
      }
    }, this.socketPingInterval); // Send a ping every 30 seconds
  }

  private clearHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
  }

  disconnect() {
    this.clearHeartbeat();
    if (this.socket) {
      this.socket.disconnect();
      this.socket = undefined;
    }
  }

  private async handleVisibilityChange() {
    document.addEventListener('visibilitychange', () => {
      if (this.socket) {
        if (!document.hidden && !this.socket.connected) {
          console.log('Tab is active again, reconnecting socket...');
          this.socket.connect();
        }
      } else {
        this.connectSocket();
      }
    });
  }

  private async handleRedisCacheUpdates(content: any) {
    if (content['cacheUpdateType'] == CacheUpdateTypes.ORG_WRAPPER) {
      const organizationUiDto = content as OrganizationWrapperUiDto;
      const oldOrganizationUiDto = await firstValueFrom(this.store.pipe(select(selectOrganizationUiDto)));

      if (oldOrganizationUiDto?.orgCode == organizationUiDto.orgCode) {
        this.store.dispatch(OnboardingActions.updateOrganizationUiDto({ organizationUiDto }))
      }
    }

    if (content['resourceType'] == CacheUpdateTypes.TENDER) {
      const tenderId = content.data as string;
      const oldTenderWrapperUiDto = await firstValueFrom(this.store.pipe(select(selectTenderUiDto)));
      if (tenderId == oldTenderWrapperUiDto?.tenderId) {
        await this.tenderService.loadtenderUiDtoByTenderId(tenderId);
      }
    }
    if (content['resourceType'] == CacheUpdateTypes.PQ) {
      const pqId = content.data as string;
      const oldPqWrapperUiDto = await firstValueFrom(this.store.pipe(select(selectPqUiDto)));
      if (pqId == oldPqWrapperUiDto?.pqId) {
        await this.pqService.loadPqUiDtoByPqId(pqId);
      }
    }

    if (content['resourceType'] == CacheUpdateTypes.AUCTION) {
      const auctionId = content.data as string;
      const oldAuctionWrapperUiDto = await firstValueFrom(this.store.pipe(select(selectAuctionUiDto)));
      if (auctionId == oldAuctionWrapperUiDto?.auctionId) {
        await this.auctionService.loadAuctionUiDtoByAuctionId(auctionId);
      }
    }
  }

  private acknowledgeNotification(notificationId: string): void {
    if (this.socket) {
      this.socket.emit('ack', { notificationId });
    }
  }

  ngOnDestroy(): void {
    this.clearHeartbeat();
    if (this.socket) {
      this.socket.emit('unregister', { id: this.socket?.id, user: this.userUiDto?.primaryEmailId });
      this.socket.disconnect();
    }
  }

}
